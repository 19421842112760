// Events definitions to be tracked
import {needsToBeNormalized} from '../../../events'

// Business mappers and builders
import {normalizedEcommerceEvent} from '../../../business/mappers/ecommerceEvents'
import {
  buildProducts, buildPromotions, buildProductTypeList,
  buildGA4Products, buildGA4Promotions,
} from './productsBuilder'

/**
 * UA Builder of ecommerce payload to send in the final datalayer object
 * @param {string} eventName Event name to recognize events vs ecommerce tracks
 * @param {object} originalPayload Payload sent from the app who calls track
 * @return {object} Ecommerce object to final dataLayer
 */
export const buildEcommercePayload = (eventName, originalPayload) => {
  let finalPayload = {}

  if (needsToBeNormalized(eventName)) {
    finalPayload = {
      ...(originalPayload.currencyCode ? {
        currencyCode: originalPayload.currencyCode,
      } : {}),
      [normalizedEcommerceEvent(eventName)]: {
        ...(originalPayload.actionField ? {
          actionField: originalPayload.actionField,
        } : {}),
        ...(originalPayload.products ? {
          products: originalPayload.products.map(buildProducts),
        } : {}),
        ...(originalPayload.promotion ? {
          promotions: [buildPromotions(originalPayload.promotion)],
        } : {}),
      },
    }
  } else {
    finalPayload = {
      currencyCode: originalPayload.currencyCode,
      impressions: originalPayload.impressions.map(buildProducts),
    }
  }

  return {
    ...(originalPayload.products ? {
      productList: buildProductTypeList(originalPayload.products),
    } : {}),
    ...(originalPayload.impressions ? {
      productList: buildProductTypeList(originalPayload.impressions),
    } : {}),
    ecommerce: {
      ...finalPayload,
    },
  }
}

/**
 * GA4 Builder of ecommerce payload to send in the final datalayer object
 * @param {string} eventName Event name to recognize events vs ecommerce tracks
 * @param {object} originalPayload Payload sent from the app who calls track
 * @return {object} Ecommerce object to final dataLayer
 */
export const buildGA4EcommercePayload = (eventName, originalPayload) => {
  const finalPayload = {
    /* transactional data */
    ...(originalPayload.actionField ? {
      ...(originalPayload.actionField.id ? {
        transaction_id: originalPayload.actionField.id,
      } : {}),
      ...(originalPayload.actionField.revenue ? {
        value: originalPayload.actionField.revenue,
      } : {}),
      ...(originalPayload.actionField.tax ? {
        tax: originalPayload.actionField.tax,
      } : {}),
      ...(originalPayload.actionField.coupon ? {
        coupon: originalPayload.actionField.coupon,
      } : {}),
    } : {}),
    /* transactional data */
    /* product data */
    ...(originalPayload.products ? {
      items: originalPayload.products.map(buildGA4Products),
    } : {}),
    ...(originalPayload.impressions ? {
      items: originalPayload.impressions.map(buildGA4Products),
    } : {}),
    ...(originalPayload.promotion ? {
      items: [buildGA4Promotions(originalPayload.promotion)],
    } : {}),
    /* product data */
  }

  return {
    ...(originalPayload.original_country ? {
      original_country: originalPayload.original_country,
    } : {}),
    ...(originalPayload.order ? {
      order: originalPayload.order,
    } : {}),
    ...(originalPayload.currencyCode ? {
      currency: originalPayload.currencyCode,
    } : {}),
    ...(originalPayload.payment_type ? {
      payment_type: originalPayload.payment_type,
    } : {}),
    ...(originalPayload.origin_country ? {
      origin_country: originalPayload.origin_country,
    } : {}),
    ...(originalPayload.origin_airport ? {
      origin_airport: originalPayload.origin_airport,
    } : {}),
    ...(originalPayload.origin_city ? {
      origin_city: originalPayload.origin_city,
    } : {}),
    ...(originalPayload.destination_country ? {
      destination_country: originalPayload.destination_country,
    } : {}),
    ...(originalPayload.destination_airport ? {
      destination_airport: originalPayload.destination_airport,
    } : {}),
    ...(originalPayload.destination_city ? {
      destination_city: originalPayload.destination_city,
    } : {}),
    ...(originalPayload.departure_date ? {
      departure_date: originalPayload.departure_date,
    } : {}),
    ...(originalPayload.arrival_date ? {
      arrival_date: originalPayload.arrival_date,
    } : {}),
    ...(originalPayload.travel_type ? {
      travel_type: originalPayload.travel_type,
    } : {}),
    ...(originalPayload.business_unit ? {
      business_unit: originalPayload.business_unit,
    } : {}),
    ...(originalPayload.adults ? {
      adults: originalPayload.adults,
    } : {}),
    ...(originalPayload.children ? {
      children: originalPayload.children,
    } : {}),
    ...(originalPayload.infants ? {
      infants: originalPayload.infants,
    } : {}),
    ...(originalPayload.products ? {
      product_list: buildProductTypeList(originalPayload.products),
    } : {}),
    ...(originalPayload.impressions ? {
      product_list: buildProductTypeList(originalPayload.impressions),
    } : {}),
    ecommerce: {
      ...finalPayload,
    },
  }
}
