// Utilities
import {
  isFunction,
} from 'analytics-utils'
import warn from '../utils/warn'

import {getGAVariable} from './vendor/ga/gaVariable'

// Constants availables
import {isValidVariable} from '../constants'

/**
 * Enables vendors connections with the public API to get variables from the
 * external services connected to the service.
 * @typedef {Function} Get
 * @param  {String}   [variableName] - Variable name to be retrived
 * @param  {Object}   [payload]      - Event payload
 * @param  {Function} [callback]     - Callback to fire after get completes
 * @return {string|object}
 * @api private
 */
const vendors = (variableName, payload, callback) => {
  if (!isValidVariable(variableName)) {
    return warn('You are trying to get a variable not available.')
  }
  if (!payload?.key) {
    return warn('The payload received is not valid.')
  }

  return getGAVariable(payload.key, (value) => {
    return isFunction(callback) ? callback(value) : value
  })
}

export default vendors
