import {string, object, array} from 'yup'
import {messages} from '../../../../utils/messages'
import {ProductShape, currencyCode} from './product'

// Export schema
export const productAddToCart = object().shape({
  currencyCode,
  actionField: object().shape({
    list: string().ensure(),
  }).noUnknown(messages.NO_UNKNOWN),
  products: array().of(
    object().shape(ProductShape).noUnknown(messages.NO_UNKNOWN),
  ).required(
    'Product add to cart requires an array of products.',
  ),
}).noUnknown(messages.NO_UNKNOWN)
