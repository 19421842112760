// Utilities
import {isFunction, storage} from 'analytics-utils'
import warn from '../../utils/warn'

// Business keys
import {API_ACTIONS} from '../././../business/keys'

/**
 * Save actions in the storage (locastorage by default)
 * @param {object} payload - Payload object with key and label
 * @param {Function} callback - Callback to be executed after save the action
 * @return {Function|void}
 * @api private
 */
const saveAction = (payload, callback) => {
  if (!payload?.key in API_ACTIONS) {
    return warn('The key is not a valid API action to save')
  }

  storage.setItem(API_ACTIONS[payload.key], payload.label || 'no-label')

  return isFunction(callback) ? callback() : null
}

export default saveAction
