// Preparing internal reusable constants values
const _cabins = ['economy', 'premium economy', 'premium business']
const _flightFares = [
  'Promo', 'Basic', 'Light', 'Plus', 'Top', 'Miles', 'Standard',
  'Full', 'Classic', 'Premium Economy', 'Premium Business',
]
const _seatsNames = [
  'Premium Business', 'Premium Economy', 'Extra Space',
  'Front Seats', 'Emergency Seats', 'Standard',
]
const _bagsNames = [
  'Personal item', 'Bag carry on', 'Bag 10 Kg',
  'Bag 15 Kg', 'Bag 23 Kg', 'Sporting Equipment',
]

const _overweight = [
  'Excess weight up to 23kg', 'Excess weight up to 32kg',
  'Excess weight up to 45kg',
]

const _flightNumber = new RegExp(/^[A-Z0-9]{2}[0-9]{2,5}$/)

// Preparing export constants
module.exports = {
  CHANNELS: {
    WEB: 'web',
    MOBILE: 'mobile',
    APP: 'app',
  },
  GA_VERSION: {
    UA: 'ua',
    GA4: 'ga4',
  },
  JOURNEYS: {
    O2C: 'O2C',
    D2D: 'D2D',
  },
  API_ACTIONS: {
    previousAction: 'xp_gaPreviousAction',
  },
  LANGUAGE_REGEX: new RegExp(/^(es|en|pt|de|it|fr)$/),
  // eslint-disable-next-line max-len
  POS_REGEX: new RegExp(/^(ar|cl|co|ec|py|pe|uy|un|mx|es|ca|us|uk|ue|au|nz|un|br|de|it|fr|gb)$/),
  // eslint-disable-next-line max-len
  COUNTRIES_REGEX: new RegExp(/(Argentina|Chile|Colombia|Ecuador|Paraguay|Perú|Uruguay|México|España|Canada|USA|UK|UE|Australia|Nueva\sZelanda|Others|Brasil|Deutschland|Italia|Francia)/),
  // eslint-disable-next-line max-len
  UUID_REGEX: new RegExp(/(^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$)|(^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$)/, 'i'),
  FAMILY: [
    'Airport', 'Ancillaries', 'Application', 'Biometric', 'ContactCenter',
    'Corporate', 'DigitalMarketing', 'DigitalSales', 'Information',
    'OrderManagement', 'Payments', 'PreFlight', 'NDC', 'Internal',
    'LATAMTravel',
  ],
  SUBFAMILY: [
    '2FA', 'AccountRecovery', 'Agent', 'Bags', 'BoardingPass', 'Booking',
    'Checkin', 'Claims', 'CMS', 'Deals', 'Destinations', 'Documentation',
    'EmailPreferences', 'Enrollment', 'Error', 'FAQ', 'Flights', 'Fulfillment',
    'HelpDesk', 'Homepage', 'LATAMPass', 'Login', 'Market', 'MyAccount',
    'MyBookings', 'Notifications', 'OrderCentric', 'PasswordRecovery', 'P2P',
    'Payment', 'Polls', 'Preferences', 'Seats', 'Signup', 'StaffTravel',
    'Tag&Drop', 'Upgrade', 'Wallet', 'Miles', 'FlightStatus', 'ContactTracing',
    'SDFC', 'LATAMTravel', 'OrdersManagement', 'PaymentsConsole', 'Agency',
    'Passenger', 'Packages', 'Hotels', 'Trips', 'Tours', 'Activities',
    'Transfers', 'Insurance', 'Cars', 'Cruises', 'Validation',
  ],
  PAGE_ACTION: [
    'Add', 'Change', 'Disable', 'Edit', 'Enable', 'Error', 'Log', 'Migration',
    'Purchase', 'Refund', 'Remove', 'Response', 'Search', 'Submit', 'Delete',
    'Transaction', 'View', 'Withdraw', 'InvoluntaryChange', 'Redirect', 'Void',
  ],
  TIMERS: [
    'xp_search-box-timing',
  ],
  PAGENAME_REGEX: new RegExp(/^[a-zA-Z0-9_\-]+$/, 'i'),
  ISO_31661_ALPHA2: [
    'AD', 'AE', 'AF', 'AG', 'AI', 'AL', 'AM', 'AN', 'AO', 'AQ', 'AR', 'AS',
    'AT', 'AU', 'AW', 'AX', 'AZ', 'BA', 'BB', 'BD', 'BE', 'BF', 'BG', 'BH',
    'BI', 'BJ', 'BL', 'BM', 'BN', 'BO', 'BQ', 'BR', 'BS', 'BT', 'BV', 'BW',
    'BY', 'BZ', 'CA', 'CC', 'CD', 'CF', 'CG', 'CH', 'CI', 'CK', 'CL', 'CM',
    'CN', 'CO', 'CR', 'CU', 'CV', 'CW', 'CX', 'CY', 'CZ', 'DE', 'DJ', 'DK',
    'DM', 'DO', 'DZ', 'EC', 'EE', 'EG', 'EH', 'ER', 'ES', 'ET', 'FI', 'FJ',
    'FK', 'FM', 'FO', 'FR', 'GA', 'GB', 'GD', 'GE', 'GF', 'GG', 'GH', 'GI',
    'GL', 'GM', 'GN', 'GP', 'GQ', 'GR', 'GS', 'GT', 'GU', 'GW', 'GY', 'HK',
    'HM', 'HN', 'HR', 'HT', 'HU', 'ID', 'IE', 'IL', 'IM', 'IN', 'IO', 'IQ',
    'IR', 'IS', 'IT', 'JE', 'JM', 'JO', 'JP', 'KE', 'KG', 'KH', 'KI', 'KM',
    'KN', 'KP', 'KR', 'KW', 'KY', 'KZ', 'LA', 'LB', 'LC', 'LI', 'LK', 'LR',
    'LS', 'LT', 'LU', 'LV', 'LY', 'MA', 'MC', 'MD', 'ME', 'MF', 'MG', 'MH',
    'MK', 'ML', 'MM', 'MN', 'MO', 'MP', 'MQ', 'MR', 'MS', 'MT', 'MU', 'MV',
    'MW', 'MX', 'MY', 'MZ', 'NA', 'NC', 'NE', 'NF', 'NG', 'NI', 'NL', 'NO',
    'NP', 'NR', 'NU', 'NZ', 'OM', 'PA', 'PE', 'PF', 'PG', 'PH', 'PK', 'PL',
    'PM', 'PN', 'PR', 'PS', 'PT', 'PW', 'PY', 'QA', 'RE', 'RO', 'RS', 'RU',
    'RW', 'SA', 'SB', 'SC', 'SD', 'SE', 'SG', 'SH', 'SI', 'SJ', 'SK', 'SL',
    'SM', 'SN', 'SO', 'SR', 'SS', 'ST', 'SV', 'SX', 'SY', 'SZ', 'TC', 'TD',
    'TF', 'TG', 'TH', 'TJ', 'TK', 'TL', 'TM', 'TN', 'TO', 'TR', 'TT', 'TV',
    'TW', 'TZ', 'UA', 'UG', 'UM', 'US', 'UY', 'UZ', 'VA', 'VC', 'VE', 'VG',
    'VI', 'VN', 'VU', 'WF', 'WS', 'YE', 'YT', 'YU', 'ZA', 'ZM', 'ZW',
  ],
  CURRENCY_CODES: [
    'ARS', 'AUD', 'BRL', 'CAD', 'CLP',
    'COP', 'EUR', 'GBP', 'NZD', 'PYG',
    'USD', 'PEN', 'UYU',
  ],
  IATA_CODE: new RegExp(/^[A-Z]{3}$/),
  // TODO: Validate dates in a better way.
  DATE: new RegExp(/^\d{4}-(0[1-9]|1[0-2])-([0-2][0-9]|3[0-1])$/),
  // eslint-disable-next-line max-len
  DATE_TIME: new RegExp(/^\d{4}-(0[1-9]|1[0-2])-([0-2][0-9]|3[0-1])\s(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/),
  TIME: new RegExp(/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/),
  // eslint-disable-next-line max-len
  OPERATING_AIRLINES: new RegExp(/^([LA|LP|4C|4M|XL|JJ|AA|IB|LH|QF|BA|2Z|UA|AM|SA|B6|AF|AC|AZ|QR|EK|4O|LX|KL|JL|KE|VT|VY|CX|IG|CA|AS|LY|9B|SK|NZ|EY|TK|JQ|AY|SQ|ET|OZ|NH|WS|OS|CZ|MH|KA|TN|MU|EW|SN|OK|CI|MN|LO|LG|AI|MS|TG|S7|OU|RJ|AT|DT|9W|HA|UL|GA|SU]{2})/),
  TRAVEL_TYPES: [
    'round trip', 'one way', 'multi city',
  ],
  CABINS: _cabins,
  FLIGHT_PATH_REGEX: new RegExp(/^([A-Z]{3}-[A-Z]{3})(-[A-Z]{3})*$/),
  // eslint-disable-next-line max-len
  SEGMENT_FLIGH_NUMBER_REGEX: new RegExp(/^[A-Z0-9]{2}[0-9]+(\s\/\s[A-Z0-9]{2}[0-9]+)*$/),
  // eslint-disable-next-line max-len
  SEGMENT_OPERATOR_REGEX: new RegExp(/^([LA|LP|4C|4M|XL|JJ|AA|IB|LH|QF|BA|2Z|UA|AM|SA|B6|AF|AC|AZ|QR|EK|4O|LX|KL|JL|KE|VT|VY|CX|IG|CA|AS|LY|9B|SK|NZ|EY|TK|JQ|AY|SQ|ET|OZ|NH|WS|OS|CZ|MH|KA|TN|MU|EW|SN|OK|CI|MN|LO|LG|AI|MS|TG|S7|OU|RJ|AT|DT|9W|HA|UL|GA|SU]{2})+(| \/ ([LA|LP|4C|4M|XL|JJ|AA|IB|LH|QF|BA|2Z|UA|AM|SA|B6|AF|AC|AZ|QR|EK|4O|LX|KL|JL|KE|VT|VY|CX|IG|CA|AS|LY|9B|SK|NZ|EY|TK|JQ|AY|SQ|ET|OZ|NH|WS|OS|CZ|MH|KA|TN|MU|EW|SN|OK|CI|MN|LO|LG|AI|MS|TG|S7|OU|RJ|AT|DT|9W|HA|UL|GA|SU]{2})+)*$/),
  BUSINESS_UNITS: ['international', 'domestic', 'regional'],
  LIMIT_PASSENGERS: {
    ADULTS: 9,
    CHILDREN: 9,
    INFANTS: 9,
  },
  CUSTOMER_FFP_CATEGORIES: [
    'LATAM', 'GOLD', 'GOLD PLUS', 'PLATINUM', 'BLACK', 'BLACK SIGNATURE',
  ],
  PAYMENT_METHODS_REGEX: new RegExp(/((,)?(^)?(credit card|ewallet))*/),
  ANTICIPATION_PURCHASE_DAYS: 330,
  GENDERS: ['M', 'F', 'I'],
  // eslint-disable-next-line max-len
  PRODUCT_ID_NAME_REGEX: new RegExp(/^((FLIGHT|SEATS|BAGS|HOTEL|CARS|SERVICE|PRIORITY|INSURANCE|PENALTY|CFE|OVERWEIGHT|FLEX|TRIP|TOUR|ACTIVITY|TRANSFER|CRUISE|CAR)(-[A-Z]{3})+|(MILES-[0-9]+|MILES))$/),
  MILES_MONEY: new RegExp(/^[0-9]{1,3}-[0-9]{1,3}$/),
  PRODUCT_BRAND: [
    ..._flightFares, // Flights
    ..._seatsNames, // Seats
    ..._bagsNames, // Bags
    ..._overweight, // Overweight
    'Service fee LATAM', // Service Fees
  ],
  // eslint-disable-next-line max-len
  IMPRESSION_LISTS_REGEX: new RegExp(/((,)?(^)?(Recommended|Economic|Fastest|Normal|Bundle [a-z0-9].*))*/),
  // eslint-disable-next-line max-len
  PRODUCT_CATEGORY_LEVEL_ONE: ['Flights', 'Seats', 'Bags', 'Hotels', 'Cars', 'Miles', 'Priority boarding', 'Service fee', 'Insurance', 'Penalty', 'CFE', 'Overweight', 'Flex', 'Trips', 'Tours', 'Activities', 'Transfers', 'Cruises'],
  PRODUCT_CATEGORY_LEVEL_TWO: _cabins,
  PRODUCT_CATEGORY_LEVEL_THREE_REGEX: new RegExp(/^(Flight)\s[0-9]{1,2}$/),
  PRODUCT_CATEGORY_LEVEL_FOUR_DEFAULT: _flightFares,
  PRODUCT_CATEGORY_LEVEL_FOUR_REGEX: new RegExp(/^(Purchase|Change|Protection)$/),
  PRODUCT_CATEGORY_LEVEL_FIVE_REGEX: _flightNumber,
  PRODUCT_VARIANT_REGEX: new RegExp(/^([0-9]{1,2}[A-L]{1}|[0-9]+|\w{3})$/),
  PNR_REGEX: new RegExp(/^[A-Z0-9]{6}$/),
  PRODUCT_MIN_NUMBER: 0,
  MAX_SCORE: 5,
  // eslint-disable-next-line max-len
  PROMO_ID_NAME_REGEX: new RegExp(/^((LASTSEARCH|NEXTTRIP|DESTINATION|LOYALTY)(-[A-Z]{3})+|(PROMO|BANNER|HERO|SLIDER)-(.*))$/),
  // eslint-disable-next-line max-len
  PROMO_POSITION: new RegExp(/^\d+$/),
  PURCHASE_MIN_MONEY: 0,
  // eslint-disable-next-line max-len
  CUSTOMER_SCOPE: ['customer', 'agent', 'corporate', 'ROLE_AGENCY_AGENT', 'ROLE_AGENCY_ADMIN'],
  PROFILE_TYPE: ['standard', 'light'],
  PASSENGER_TYPE: ['Adult', 'Child', 'Infant'],
  SESSION_CONTROL: ['start', 'end'],
  FLIGHT_TYPE: ['outbound', 'inbound'],
  SEARCH_TYPE: ['order/pnr', 'order', 'pnr', 'ffn', 'document', 'email', 'lastname'],
  MOBILE_ACCESS_COOKIE: 'mobile_access',
}
