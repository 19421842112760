const PRODUCT_DIMENSIONS = [
  'flightPath', 'segmentFlightNumber', 'segmentOperator',
  'departureDatetime', 'arrivalDatetime', 'flightLayoverDuration',
  'flightDuration', 'upsellAmount', 'upgradeAmount', 'bookingPrice',
  'bookingPeriod', 'stars', 'cancellationPeriod', 'bookingId',
  'amenities', 'productTax', 'passengerType', 'segments', 'miles',
  'refundAmount', 'milesMoneyCombination',
]

const PROMOTION_DIMENSIONS = [
  'promotionReturnDate', 'promotionDepartureDate', 'promotionName',
  'promotionDiscount', 'promotionPrice', 'promotionCabinType',
  'promotionTargeting', 'promotionLoyaltyPrice', 'promotionProduct',
]

export const isAvailable = (dimensionKey, type) => {
  if (type == 'product') {
    return PRODUCT_DIMENSIONS.includes(dimensionKey)
  } else {
    return PROMOTION_DIMENSIONS.includes(dimensionKey)
  }
}

const UA_CUSTOM_PRODUCT_KEYS = {
  flightPath: 'dimension22',
  segmentFlightNumber: 'dimension23',
  segmentOperator: 'dimension24',
  departureDatetime: 'dimension26',
  arrivalDatetime: 'dimension28',
  flightLayoverDuration: 'metric1',
  flightDuration: 'metric2',
  upsellAmount: 'metric6',
  upgradeAmount: 'metric7',
  bookingPrice: 'metric26',
  bookingPeriod: 'metric27',
  stars: 'dimension63',
  cancellationPeriod: 'metric16',
  bookingId: 'dimension15',
  amenities: 'dimension61',
  productTax: 'metric8',
  passengerType: 'dimension53',
  segments: 'metric28',
  miles: 'metric29',
  refundAmount: 'metric23',
  milesMoneyCombination: 'dimension77',
}

export const customsProductKeys = (dimensionKey) => {
  return UA_CUSTOM_PRODUCT_KEYS[dimensionKey] || dimensionKey
}

export const customsPromotionKeys = (dimensionKey) => {
  return dimensionKey
}

const GA4_CUSTOM_PRODUCT_KEYS = {
  flightPath: 'flight_path',
  segmentFlightNumber: 'segment_flight_number',
  segmentOperator: 'segment_operator',
  departureDatetime: 'departure_datetime',
  arrivalDatetime: 'arrival_datetime',
  flightLayoverDuration: 'flight_layover_duration',
  flightDuration: 'flight_duration',
  upsellAmount: 'upsell_amount',
  upgradeAmount: 'upgrade_amount',
  bookingPrice: 'booking_price',
  bookingPeriod: 'booking_period',
  stars: 'stars',
  cancellationPeriod: 'cancellation_period',
  bookingId: 'booking_id',
  amenities: 'amenities',
  productTax: 'product_tax',
  passengerType: 'passenger_type',
  segments: 'segments',
  miles: 'miles',
  refundAmount: 'refund_amount',
  milesMoneyCombination: 'miles_money_combination',
}

export const customsGA4ProductKeys = (dimensionKey) => {
  return GA4_CUSTOM_PRODUCT_KEYS[dimensionKey] || dimensionKey
}

const GA4_CUSTOM_PROMOTION_KEYS = {
  promotionReturnDate: 'promotion_return_date',
  promotionDepartureDate: 'promotion_departure_date',
  promotionName: 'promotion',
  promotionDiscount: 'promotion_discount',
  promotionPrice: 'promotion_price',
  promotionLoyaltyPrice: 'promotion_loyalty_price',
  promotionCabinType: 'promotion_cabin',
  promotionTargeting: 'promotion_targeting',
  promotionProduct: 'promotion_product',
}

export const customsGA4PromotionKeys = (dimensionKey) => {
  return GA4_CUSTOM_PROMOTION_KEYS[dimensionKey] || dimensionKey
}

const UA_PRODUCT_KEYS = {
  id: 'item_id',
  name: 'item_name',
  brand: 'item_brand',
  variant: 'item_variant',
  list: 'item_list_name',
  position: 'index',
}

export const productKeysGA4 = (dimensionKey) => {
  return UA_PRODUCT_KEYS[dimensionKey] || dimensionKey
}

const GA4_PROMOTION_KEYS = {
  id: 'promotion_id',
  name: 'promotion_name',
  creative: 'creative_name',
  promotionName: 'promotion',
  promotionPrice: 'price',
  position: 'index',
}

export const promotionKeysGA4 = (dimensionKey) => {
  return GA4_PROMOTION_KEYS[dimensionKey] || dimensionKey
}

export const GA4ProductKeyList = [
  'id', 'name', 'brand', 'variant', 'list', 'position',
]

export const GA4PromotionKeyList = [
  'id', 'name', 'creative', 'position',
]
