// Externals TODO: Use this to implement utm_params track event
// import {paramsParse} from 'analytics-utils'

// Utilities
import warn from './utils/warn'
import globalContext from './utils/global'

// Business definitions
import {JOURNEYS, GA_VERSION} from './business/keys'

// Modules
import {createTrackHandler} from './handlers/track'
import {createPageHandler} from './handlers/page'
import {createContextHandler} from './handlers/context'
import {createGetHandler} from './handlers/get'

/**
 * Analytics library configuration
 *
 * After the library is initialized with config,
 * the core API is exposed and ready for use in the application.
 *
 * @param {object} config - analytics core config
 * @param {string} [config.app] - Name of site / app
 * @param {string} [config.gaVersion] - GA Version of your app
 * @param {string} [config.customerJourney] - The customer journey of your app
 * @return {function} {AnalyticsInstance} Analytics Instance
 * @example
 *
 * import Analytics from 'latam-xp-analytics-service'
 * @example
 * const analytics = Analytics({
 *  app: 'engage',
 *  customerJourney: 'O2C',
 *  gaVersion: 'ga4',
 * })
 *
 */
export function analytics(config = {}) {
  const {app, customerJourney, gaVersion} = config
  // Validation of the config
  if (!app) {
    return warn('The app key is required.')
  }
  if (![JOURNEYS.O2C, JOURNEYS.D2D].includes(customerJourney)) {
    return warn('The customerJourney key is not valid.')
  }

  if (!!gaVersion && ![GA_VERSION.UA, GA_VERSION.GA4].includes(gaVersion)) {
    return warn('The gaVersion key is not valid.')
  }

  // Initialize the baseConfig of all objects
  const baseConfig = {
    app,
    customerJourney,
  }

  // Set baseConfig in the global context
  globalContext.baseConfig = {
    ...baseConfig,
  }

  // Set analytics version
  globalContext.gaVersion = !!gaVersion ? gaVersion : null

  /**
   * Analytic instance returned from initialization
   * @typedef {Object} AnalyticsInstance
   * @property {Identify} identify - Identify a user
   * @property {Track} track - Track an analytics event
   * @property {Page} page - Trigger page view
   */
  const instance = {
    page: createPageHandler(baseConfig),
    track: createTrackHandler(),
    context: createContextHandler(baseConfig),
    get: createGetHandler(),
  }
  /* Return analytics instance */
  return instance
}
