const PAGE_DIMENSIONS = [
  'previousAction', 'message', 'errorName', 'responseName',
  'transactionName', 'originCountry', 'destinationCountry',
  'originAirport', 'destinationAirport', 'originCity', 'destinationCity',
  'departureDate', 'departureDateTime', 'arrivalDate', 'arrivalDateTime',
  'travelType', 'cabinType', 'currency', 'flightPath',
  'segmentFlightNumber', 'segmentOperator', 'anticipationOfPurchase',
  'businessUnit', 'adults', 'children', 'infants', 'paymentMethod',
  'order', 'funnelDetails', 'orderStatus', 'usingMiles', 'miles',
  'installments', 'checkoutAmount', 'transactionAmount',
  'transactionCreditAmount', 'transactionMilesAmount',
  'transactionWalletAmount', 'promotionPrice', 'promotionName',
  'promotionTargeting', 'promotionDiscount', 'promotionDepartureDate',
  'promotionReturnDate', 'promotionCabinType', 'flightLayoverDuration',
  'flightDuration', 'upsellAmount', 'upgradeAmount', 'bookingPrice',
  'bookingPeriod', 'stars', 'cancellationPeriod', 'bookingId',
  'amenities', 'segments', 'passengerType', 'appTrackId', 'appSessionId',
  'xpSessionId', 'resultsOrderBy', 'refundAmount', 'orderDetails',
]

export const isAvailable = (dimensionKey) => {
  return PAGE_DIMENSIONS.includes(dimensionKey)
}
