// Utilities
import warn from './warn'

/**
 * Compute seconds sincee a given timestamp
 * @param {string} timestamp to compute seconds since this date
 * @return {number} number of seconds since timestamp
 */
export default function secondsPassedSince(timestamp) {
  if (typeof timestamp != 'number') {
    warn('The timestamp value is not valid.')
    return 0
  }
  return Math.abs(+new Date() - timestamp) / 1000
}
