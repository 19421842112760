// Business mappers
import {
  isAvailable, customsProductKeys, customsPromotionKeys,
  customsGA4ProductKeys, customsGA4PromotionKeys,
  productKeysGA4, promotionKeysGA4, GA4ProductKeyList, GA4PromotionKeyList,
} from '../../../business/mappers/productDimensions'

import warn from '../../../utils/warn'

/**
 * UA Builder of custom dimension/metrics for ecommerce payload.
 * @param {object} originalPayload Payload sent from the app who calls track in
 * @param {string} type Type of payload being sent: product, promotion
 * the product object
 * @return {object}
 */
export const buildEcommerceCustoms = (originalPayload, type) => {
  const payload = {...originalPayload}
  const keys = (type == 'product') ? customsProductKeys : customsPromotionKeys
  const customs = {}
  if (payload.custom) {
    // eslint-disable-next-line prefer-const
    for (let [key, value] of Object.entries(payload.custom)) {
      if (isAvailable(key, type)) {
        customs[keys(key)] = value
      } else {
        warn(`The key ${key} is not available as ${type} dimension.`)
      }
    }
    delete payload.custom
  }
  return {
    ...payload,
    ...customs,
  }
}

/**
 * GA4 Builder of custom dimension/metrics for ecommerce payload.
 * @param {object} originalPayload Payload sent from the app who calls track in
 * @param {string} type Type of payload being sent: product, promotion
 * the product object
 * @return {object}
 */
export const buildGA4EcommerceCustoms = (originalPayload, type) => {
  const payload = {...originalPayload}
  const keys = type == 'product' ? productKeysGA4 : promotionKeysGA4
  // eslint-disable-next-line max-len
  const customKeys = type == 'product' ? customsGA4ProductKeys : customsGA4PromotionKeys
  // eslint-disable-next-line max-len
  const availableKeys = type == 'product' ? GA4ProductKeyList : GA4PromotionKeyList
  const customs = {}
  // Translate keys
  // eslint-disable-next-line prefer-const
  for (let [key, value] of Object.entries(payload)) {
    if (availableKeys.indexOf(key) != -1) {
      payload[keys(key)] = value
      delete payload[key]
    }
  }
  // Translate custom keys
  if (payload.custom) {
    // eslint-disable-next-line prefer-const
    for (let [key, value] of Object.entries(payload.custom)) {
      if (isAvailable(key, type)) {
        customs[customKeys(key)] = value
      } else {
        warn(`The key ${key} is not available as ${type} dimension.`)
      }
    }
    delete payload.custom
  }
  return {
    ...payload,
    ...customs,
  }
}
