import {string, object, number, array} from 'yup'
import {messages} from '../../../../utils/messages'
import {ProductRefundShape, currencyCode} from './product'

// Export refund schemas
export const productRefund = object().shape({
  currencyCode,
  actionField: object().required().shape({
    id: string().ensure().required(
      'Purchase ID (Order ID) is required.',
    ),
    revenue: number(),
  }).noUnknown(messages.NO_UNKNOWN),
  products: array().of(object().shape(ProductRefundShape)),
}).noUnknown(messages.NO_UNKNOWN)
