/* global VERSION */

// Business mappers
import {isAvailable} from '../business/mappers/pageDimensions'

// Business and validation schemas
import {
  validate, schemas,
} from '../utils/validations'
import {API_ACTIONS, CHANNELS, MOBILE_ACCESS_COOKIE} from '../business/keys'

// Utilities
import {
  inBrowser, storage,
} from 'analytics-utils'
import timeStamp from '../utils/timestamp'
import sessionID from '../utils/sessionID'
import existsGTM from '../utils/existsGTM'
import reportError from '../utils/reportError'
import withCallbacks from '../utils/withCallbacks'
import {resetDataLayer} from '../utils/dataLayer'
import getCookie from '../utils/cookies'

import warn from '../utils/warn'

/**
 * Page url path
 * @param {string} url to solve the Path
 * @return {string} url path
 */
function urlPath(url) {
  const regex = /(http[s]?:\/\/)?([^\/\s]+\/)(.*)/g
  const matches = regex.exec(url)
  const hashRegex = /#.*$/
  // eslint-disable-next-line max-len
  const pathMatch = (matches && matches[3]) ? matches[3].split('?')[0].replace(hashRegex, '') : ''
  return `/${pathMatch}`
}

/**
 * Return internal source from url
 * @param {string} url
 * @return {string} source
 */
function internalSource(url) {
  const regex = new RegExp('[?&]latam_source(=([^&#]*)|&|#|$)')
  const results = regex.exec(url)
  if (!results || !results[2]) return ''
  return results[2]
}

/**
 * Infer channel from cookie value
 * @return {string} Navigation channel
 */
function inferChannel() {
  return getCookie(MOBILE_ACCESS_COOKIE) ? CHANNELS.MOBILE : CHANNELS.WEB
}
export {inferChannel}

/**
 * Return the current URL and remove the hash.
 * @return {string} current URL
 */
function currentUrl() {
  const hashRegex = /#.*$/
  return window.location.href.replace(hashRegex, '')
}

/**
 * Page data for overides
 * @typedef {object} PageData
 * @property {string} [title] - Page title
 * @property {string} [url] - Page url
 * @property {string} [path] - Page path
 * @property {string} [search] - Page search
 * @property {string} [width] - Page width
 * @property {string} [height] - Page height
*/

/**
 * Get information about current page
 * @typedef {Function} getPageData
 * @param  {PageData} [pageData = {}] - Page data overides
 * @return {PageData} resolved page data
 */
export const getPageData = (pageData = {}) => {
  if (!inBrowser) return pageData
  const {location, innerWidth, innerHeight} = window
  const {hash, search} = location
  const url = currentUrl()
  const page = {
    title: document?.title ? document.title : 'No Title',
    url: url,
    path: urlPath(url),
    hash: hash,
    search: search,
    width: innerWidth,
    height: innerHeight,
    internalSource: internalSource(url),
    referrer: document.referrer.replace(/utm_[a-zA-Z]+=(\d|\w|\||_|-)+&?/igm, ''),
  }

  return {
    ...page,
    ...pageData,
  }
}

/**
 * Pagename builder
 * @param {string} country - The originalCountry page value
 * @param {string} channel - The channel page value
 * @param {string} family - The Family page value
 * @param {string} subFamily - The SubFamily page value
 * @param {string} action - The Action page value
 * @param {string} pageName - The name of the page
 * @return {string} Pagename
 */
function buildPagename(country, channel, family, subFamily, action, pageName) {
  return `${country}|${channel}|${family}|${subFamily}|${action}|${pageName}`
}
export {buildPagename}

/**
 * Custom object builder
 * @param {string} data - Original payload
 * @return {object} custom object
 */
function buildCustom(data) {
  const customs = {}
  if (data.custom) {
    // eslint-disable-next-line prefer-const
    for (let [key, value] of Object.entries(data.custom)) {
      if (isAvailable(key)) {
        customs[key] = value
      } else {
        warn(`The key ${key} is not available as page dimension.`)
      }
    }
    return {
      custom: customs,
    }
  } else {
    return {}
  }
}

/**
 * Return previous action from the storage (localstorage by default)
 * @return {Object}
 */
function previousAction() {
  const previousAction = storage.getItem(API_ACTIONS.previousAction)
  if (!previousAction) return {}

  // Remove the previous saved variable to avoid repetition
  storage.removeItem(API_ACTIONS.previousAction)

  // Previous action as key-value to datalayer object
  return {
    previousAction,
  }
}

/**
 * Builder of page payload
 * @param {string} data Data sent from the app who calls page event
 * @return {object}
 */
const buildPagePayload = (data) => {
  data.channel = inferChannel()
  data = {
    path: data.path,
    app: data.app,
    channel: data.channel,
    customerJourney: data.customerJourney,
    language: data.language,
    country: data.country,
    originalCountry: data.originalCountry,
    title: data.title,
    family: data.family,
    subFamily: data.subFamily,
    action: data.action,
    pageName: buildPagename(
      data.originalCountry,
      data.channel,
      data.family,
      data.subFamily,
      data.action,
      data.pageName,
    ),
    ...(data.source ? {
      source: data.source,
    } : {}),
    ...(data.referrer ? {
      referrer: data.referrer,
    } : {}),
    ...(data.userID ? {
      userID: data.userID,
    } : {}),
    ...(data.customerScope ? {
      customerScope: data.customerScope,
    } : {}),
    ...(data.customerProfile ? {
      customerProfile: data.customerProfile,
    } : {}),
    ...(data.customerFFPCategory ? {
      customerFFPCategory: data.customerFFPCategory,
    } : {}),
    ...(data.customerGender ? {
      customerGender: data.customerGender,
    } : {}),
    ...(data.customerActiveUser ? {
      customerActiveUser: data.customerActiveUser,
    } : {}),
    ...(data.impersonatedUserID ? {
      impersonatedUserID: data.impersonatedUserID,
    } : {}),
    ...(data.impersonatedCustomerScope ? {
      impersonatedCustomerScope: data.impersonatedCustomerScope,
    } : {}),
    ...(data.sessionControl ? {
      sessionControl: data.sessionControl,
    } : {}),
    ...(data.extras ? {
      extras: data.extras,
    } : {}),
    ...buildCustom(data),
  }
  return data
}

/**
 * Validates page data and inject into datalayer method
 * @typedef {Function} Page
 * @param  {PageData} [data] - Page data overrides.
 * @param  {Function} [dataLayer] - Page tracking datalayer method
 * @param  {Function} [callback] - Callback to fire after page call completes
 * @return {Void}
 * @api private
 */
const page = (data, dataLayer, callback) => {
  return validate(schemas.page, data).then(() => {
    const eventName = 'pageview'
    const payload = buildPagePayload(data)

    // Reset GTM variables for UA to prevent persistance across pages on SPA
    resetDataLayer('custom')

    dataLayer({
      ...payload,
      libVersion: VERSION, // VERSION will be injected by webpack globally.
      timestamp: timeStamp(),
      sessionID: sessionID(),
      ...previousAction(),
      event: eventName,
      ...withCallbacks(callback),
    })

    existsGTM(callback)
  }).catch((error) => reportError(error))
}

export default page
