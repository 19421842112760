import {string, object, number, array, mixed} from 'yup'
import {messages} from '../../../../utils/messages'
import {
  PURCHASE_MIN_MONEY, CURRENCY_CODES,
} from '../../../../business/keys'
import {ProductShape} from './product'

// Export purchase schemas
export const productPurchase = object().shape({
  currencyCode: mixed().required().oneOf(CURRENCY_CODES,
    'currency key has not valid ISO-4217 value',
  ),
  actionField: object().required().shape({
    id: string().ensure().required(
      'Purchase ID (Order ID) is required.',
    ),
    revenue: number().min(PURCHASE_MIN_MONEY, messages.MIN_NUM),
    tax: number().min(PURCHASE_MIN_MONEY, messages.MIN_NUM),
    coupon: string().ensure(),
    list: string().ensure(),
  }).noUnknown(messages.NO_UNKNOWN),
  products: array().of(object().shape(ProductShape)).required(
    'Product purchase requires an array of products.',
  ),
}).noUnknown(messages.NO_UNKNOWN)
