import {string, object, array} from 'yup'
import {messages} from '../../../../utils/messages'
import {ProductShape, currencyCode} from './product'

// Export checkout schemas
export const productCheckout = object().shape({
  currencyCode,
  actionField: object().required().shape({
    step: string().ensure().required(
      'Checkout step is required.',
    ),
    option: string().ensure(),
    list: string().ensure(),
  }).noUnknown(messages.NO_UNKNOWN),
  products: array().of(object().shape(ProductShape)).required(
    'Product checkout requires an array of products.',
  ),
}).noUnknown(messages.NO_UNKNOWN)
