// GA4 event mapper
const eventMapper = {
  'filterResults': 'filter_results',
  'orderResults': 'order_results',
  'errorLog': 'error_log',
  'responseLog': 'response_log',
  'validationLog': 'validation_log',
  'passengerSelection': 'passenger_selection',
  'checkoutStep': 'checkout_step',
  'repricing': 'repricing',
  'dataInit': 'data_init',
  'seatSelection': 'seat_selection',
  'ancillaryMarket': 'ancillary_market',
  'funnelStep': 'funnel_step',
  'searchResults': 'search_results',
  'changeProduct': 'change_product',
  'orderData': 'order_data',
}

export const normalizedEvent = (eventName) => {
  return eventMapper[eventName]
}
