import {string, mixed, object, number, boolean} from 'yup'
import {messages} from '../../../utils/messages'
import {
  LANGUAGE_REGEX, POS_REGEX, JOURNEYS, UUID_REGEX,
  FAMILY, SUBFAMILY, PAGE_ACTION, PAGENAME_REGEX, CHANNELS,
  CUSTOMER_SCOPE, CUSTOMER_FFP_CATEGORIES, PROFILE_TYPE,
  ISO_31661_ALPHA2, IATA_CODE, DATE, TRAVEL_TYPES, BUSINESS_UNITS,
  LIMIT_PASSENGERS,
} from '../../../business/keys'

export const defaultEcommerceData = {
  transactionName: string().min(3, messages.MIN_LEN),
  originalCountry: string().matches(POS_REGEX,
    'The originalCountry key is invalid.',
  ),
  originCountry: mixed().oneOf(ISO_31661_ALPHA2,
    'originCountry key has not valid ISO 3166-1 Alpha2 value.',
  ),
  originAirport: string().matches(IATA_CODE,
    'originAirport key has not valid IATA Code value.',
  ),
  originCity: string().matches(IATA_CODE,
    'originCity key has not valid IATA Code value.',
  ),
  destinationCountry: mixed().oneOf(ISO_31661_ALPHA2,
    'destinationCountry key has not valid ISO 3166-1 Alpha2 value.',
  ),
  destinationAirport: string().matches(IATA_CODE,
    'destinationAirport key has not valid IATA Code value.',
  ),
  destinationCity: string().matches(IATA_CODE,
    'destinationCity key has not valid IATA Code value.',
  ),
  departureDate: string().matches(DATE,
    'departureDate key has not valid Date value.',
  ),
  arrivalDate: string().matches(DATE,
    'arrivalDate key has not valid Date value.',
  ),
  travelType: mixed().oneOf(TRAVEL_TYPES,
    'travel_type key is not a valid value.',
  ),
  businessUnit: mixed().oneOf(BUSINESS_UNITS,
    'business_unit key is not a valid value.',
  ),
  adults: number().max(LIMIT_PASSENGERS.ADULTS, messages.MAX_NUM),
  children: number().max(LIMIT_PASSENGERS.CHILDREN, messages.MAX_NUM),
  infants: number().max(LIMIT_PASSENGERS.INFANTS, messages.MAX_NUM),
  order: string().ensure(),
}

export default {
  path: string().required(
    'The path key is required.',
  ),
  app: string().required(
    'The app key is required.',
  ),
  isPageview: boolean(),
  channel: mixed().oneOf([CHANNELS.WEB, CHANNELS.MOBILE, CHANNELS.APP],
    'The channel key is not valid.',
  ),
  trackID: string().matches(UUID_REGEX,
    'trackID key is not a valid uuid value.',
  ),
  customerJourney: mixed().required().oneOf([JOURNEYS.O2C, JOURNEYS.D2D],
    'The customerJourney key is not valid.',
  ),
  language: string().required().matches(LANGUAGE_REGEX,
    'The language key is invalid.',
  ),
  country: string().required().matches(POS_REGEX,
    'The country key is invalid.',
  ),
  title: string().required(
    'The title key is required.',
  ),
  family: mixed().required().oneOf(FAMILY,
    'The family key is not valid.',
  ),
  subFamily: mixed().required().oneOf(SUBFAMILY,
    'The subfamily key is not valid.',
  ),
  action: mixed().required().oneOf(PAGE_ACTION,
    'The action key is not valid.',
  ),
  pageName: string().required().matches(PAGENAME_REGEX,
    'The pageName key is not valid.',
  ),
  customer: object().shape({
    userID: string().matches(UUID_REGEX,
      'The User ID is not a valid UUID identifier.',
    ),
    assistedUserID: string().matches(UUID_REGEX,
      'The Assisted User ID is not a valid UUID identifier.',
    ),
    assistedCustomerScope: mixed().oneOf(CUSTOMER_SCOPE,
      'The Assisted Customer Scope is not valid.',
    ),
    customerScope: mixed().oneOf(CUSTOMER_SCOPE,
      'The Customer Scope is not valid.',
    ),
    customerFFPCategory: mixed().oneOf(CUSTOMER_FFP_CATEGORIES,
      'The Customer FFP Category is not valid.',
    ),
    customerProfile: mixed().oneOf(PROFILE_TYPE,
      'The Customer Profile is not valid.',
    ),
  }).noUnknown(messages.NO_UNKNOWN),
  context: object().required().shape({
    ...defaultEcommerceData,
    subflow: string().ensure(),
  }).noUnknown(messages.NO_UNKNOWN),
  extras: object().noUnknown(messages.NO_UNKNOWN),
}
