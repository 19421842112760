import {string, mixed, number, boolean} from 'yup'
import {messages} from './messages'
import {
  CURRENCY_CODES, PAYMENT_METHODS_REGEX, PURCHASE_MIN_MONEY, LIMIT_PASSENGERS,
  TIME, FLIGHT_TYPE, SEARCH_TYPE, ISO_31661_ALPHA2, PNR_REGEX, IATA_CODE, CABINS,
  DATE, DATE_TIME, PRODUCT_BRAND, POS_REGEX,
} from '../business/keys'

export const stringRequired = string().required()
export const stringEnsure = string().ensure()
export const message = string().min(3, messages.MIN_LEN)
export const name = string().min(3, messages.MIN_LEN)
export const currency = mixed().oneOf(CURRENCY_CODES,
  'currency key has not valid ISO-4217 value',
)
export const tax = number().min(PURCHASE_MIN_MONEY, messages.MIN_NUM)
export const paymentType = string().matches(PAYMENT_METHODS_REGEX,
  'paymentType key is not a valid value.',
)
export const resultsType = mixed().oneOf([...FLIGHT_TYPE, ...SEARCH_TYPE],
  'resultsType is not a valid value.',
)
export const totalResults = number().required()
export const firstDeparture = string().matches(TIME,
  'firstDeparture key has not valid Time value.',
)
export const lastDeparture = string().matches(TIME,
  'lastDeparture key has not valid Time value.',
)
export const transactionName = string().min(4, messages.MIN_LEN)
export const originCountry = mixed().oneOf(ISO_31661_ALPHA2,
  'originCountry key has not valid ISO 3166-1 Alpha2 value.',
)
export const booleanValue = boolean()
export const adults = number().max(LIMIT_PASSENGERS.ADULTS, messages.MAX_NUM)
export const children = number().max(LIMIT_PASSENGERS.CHILDREN, messages.MAX_NUM)
export const infants = number().max(LIMIT_PASSENGERS.INFANTS, messages.MAX_NUM)
export const originAirport = string().matches(IATA_CODE,
  'originAirport key has not valid IATA Code value.',
)
export const destinationAirport = string().matches(IATA_CODE,
  'destinationAirport key has not valid IATA Code value.',
)
export const date = string().matches(DATE,
  'The date key has not valid Date value.',
)
export const departureDateTime = string().matches(DATE_TIME,
  'departureDateTime key has not valid Date Time value.',
)
export const arrivalDateTime = string().matches(DATE_TIME,
  'arrivalDateTime key has not valid Date Time value.',
)
export const cabinType = mixed().oneOf(CABINS,
  'cabinType key is not a valid value.',
)
export const flightBrand = mixed().oneOf(PRODUCT_BRAND,
  'The brand is not valid for this product object.',
)
export const country = string().required().matches(POS_REGEX,
  'The country key is invalid.',
)
export const pnr = string().matches(PNR_REGEX,
  'The PNR key has not valid PNR value.',
)
