import {object, array} from 'yup'
import {messages} from '../../../../utils/messages'

import {currency, stringEnsure, paymentType} from '../../../../utils/validations.ga4'
import {defaultEcommerceData} from '../context'
import {ProductShape} from './product'

// Export schema
export const productAddPaymentInfo = object().shape({
  ...defaultEcommerceData,
  currency,
  value: stringEnsure,
  paymentType,
  products: array().of(object().shape(ProductShape)).required(
    'productAddPaymentInfo requires an array of products.',
  ),
}).noUnknown(messages.NO_UNKNOWN)
