import {
  isFunction,
} from 'analytics-utils'
// import warn from '../utils/warn'

/**
 * Validate if exists GTM, if not exists, return a callback.
 * @param {function} callback Function callback to return if GTM does not exists
 * @return {void}
 */
export default function existsGTM(callback) {
  if (!window.google_tag_manager) {
    // TODO: Show a message like this to alert GTM is not working?
    // warn(`Your app do not have an instance of Google Tag Manager,
    //   your created dataLayer will not send anywhere.`)
    if (isFunction(callback)) callback()
  }
}
