import {
  isFunction,
} from 'analytics-utils'
import warn from '../../../utils/warn'

/**
 * @name getGAVariable
 * @param  {String} variable name to retrieve from GA
 * @return {string|object}
 */
const getGAVariable = (variable) => {
  if (window.ga?.loaded) {
    if (isFunction(window.ga.getAll) && window.ga.getAll()) {
      const tracker = window.ga.getAll()[0]
      return tracker.get(variable)
    }
  }

  // If GA is not loaded, this will return a 'no-data' and logs a warning
  warn('GA is not loaded to get a vendor variable, <no-data> will be seted')
  return 'no-data'
}

export {
  getGAVariable,
}
