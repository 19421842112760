import {isFunction} from 'analytics-utils'
// eslint-disable-next-line import/no-extraneous-dependencies
import {TAG_MANAGER_ID} from 'latam-xp-analytics-plugin-gtm/src/config'

const callbackHandler = (callback) => (containerId) => {
  if (TAG_MANAGER_ID === containerId) {
    callback()
  }
}

/**
 * With callbacks enables you to use eventCallback
 * features in Google Tag Manager.
 * @see https://www.simoahava.com/gtm-tips/hitcallback-eventcallback/
 *
 * @param {function} callback Callback function to be executed.
 * @return {object}
 */
export default function withCallbacks(callback) {
  const cbObject = {}
  if (isFunction(callback)) {
    cbObject.eventCallback = callbackHandler(callback)
    cbObject.eventTimeout = 3000
  }

  return cbObject
}
