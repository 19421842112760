import {isObject} from 'analytics-utils'

import getCallback from '../utils/getCallback'
import page, {getPageData} from '../modules/page'
import {dataLayer} from '../utils/dataLayer'
import {CHANNELS} from '../business/keys'

export const createPageHandler = (baseConfig) => {
  /**
   * Trigger page view. This will trigger `page` calls
   * @typedef {Function} Page
   * @param  {PageData} [data] - Page data overrides.
   * @param  {Function} [callback] - Callback to fire after page call completes
   * @return {Promise}
   * @api public
   *
   * @example
   *
   * // Basic page tracking
   * analytics.page({
   *   family: 'DigitalMarketing',
   *   subFamily: 'Homepage',
   *   action: 'View',
   *   pageName: 'Home',
   *   pos: 'ec',
   *   language: 'es',
   * })
   *
   * // Page tracking with User ID identification
   * analytics.page({
   *   userId: '284e1b12-c0e5-4df5-b2cd-5aad8a751c47',
   * })
   *
   * // Page tracking with custom dimensions
   * analytics.page({
   *   custom: {
   *     originAirport: 'SCL',
   *     destinationAirport: 'ANF',
   *     travelType: 'round trip',
   *   },
   *})
   *
   * // Fire callback with 1st, 2nd or 3rd argument
   * analytics.page(() => {
   *   console.log('do this after page tracking')
   * })
   */
  return (data, callback) => {
    const {path, title, referrer, internalSource} = getPageData()

    // Validate the data object
    const validatedData = isObject(data) ? data : {}

    // Set the default values when not received
    if (!validatedData.path) validatedData.path = path
    if (!validatedData.title) validatedData.title = title
    if (!validatedData.referrer) validatedData.referrer = referrer
    if (!validatedData.source) validatedData.source = internalSource
    if (!validatedData.channel) validatedData.channel = CHANNELS.WEB

    return page(
      {
        ...validatedData,
        ...baseConfig,
      },
      dataLayer,
      getCallback(data, callback),
    )
  }
}
