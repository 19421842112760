// Utilities
import {dataLayer} from './dataLayer'
import timeStamp from './timestamp'
import warn from './warn'
import globalContext from '../utils/global'
import isDefaultVersion from '../utils/isDefaultVersion'

// Helper from page module
import {getPageData} from '../modules/page'
import {GA_VERSION} from '../business/keys'

/**
 * @name reportError
 * @param {error} error - The error object
 * @return {void}
 */
export default function reportError(error) {
  // Display the error in console
  warn(error)

  // Send the error as datalayer object
  if (globalContext.gaVersion == null) {
    sendDataLayerByVersion(error, GA_VERSION.UA)
    sendDataLayerByVersion(error, GA_VERSION.GA4)
  } else {
    sendDataLayerByVersion(error, globalContext.gaVersion)
  }
}

/**
 * Build for the given GA version a payload and then send it to GA
 * @typedef {Function} sendDataLayerByVersion
 * @param  {object} [error] - Error data.
 * @param  {string} [gaversion] - Google Analytics version to build playload
 * @api private
 */
export function sendDataLayerByVersion(error, gaversion) {
  const {path} = getPageData()
  const {app, customerJourney} = globalContext.baseConfig
  const errorPayload = buildErrorPayload(error, gaversion)

  dataLayer({
    path,
    app,
    customerJourney,
    ...errorPayload,
    libVersion: VERSION, // VERSION will be injected by webpack globally.
    timestamp: timeStamp(),
    gaVersion: gaversion,
    event: 'library',
  })
}

/**
 * Builder of error payload
 * @param  {object} [error] - Error data.
 * @param {string} gaversion GA version to build payload
 * @return {object}
 */
const buildErrorPayload = (error, gaversion) => {
  const name = error?.name ? error.name : 'no-name'
  const message = error?.name ?
    error.message + ': ' + error.params.originalValue : 'no-path'

  if (isDefaultVersion(gaversion)) {
    return {
      category: 'library-validation-error',
      action: name,
      label: name == 'ValidationError' ? message : 'no-path',
    }
  } else {
    return {
      parameters: {
        name: name,
        message: name == 'ValidationError' ? message : 'no-path',
      },
    }
  }
}
