import {isString} from 'analytics-utils'

import getCallback from '../utils/getCallback'
import vendors from '../modules/vendors'

/**
 * Get variable from the inside of analytics instance.
 * @typedef {Function} get
 * @param  {String}   [variableName] - Vairbale name to retrive
 * @param  {Object}   [payload]      - Event payload
 * @param  {Function} [callback]     - Callback to fire after get completes
 * @return {string|object}
 * @api public
 *
 * @example
 *
 * // Get a gaVariable with a key called linkerParam
 * analytics.get('gaVariable', {
 *     key: 'linkerParam',
 * }, (value) => {
 *   console.log(value);
 * })
 */
export const createGetHandler = () => (variableName, payload, callback) => {
  if (!variableName || !isString(variableName)) {
    return warn('Variable name missing')
  }
  const data = payload || {}

  return vendors(
    variableName,
    {
      ...data,
    },
    getCallback(variableName, payload, callback),
  )
}
