// Events definitions to be tracked
import {getInteractiveEvents} from '../../../events'

// Business mappers and builders
import {normalizedEvent} from '../../../business/mappers/customEvents.ga4'

/**
 * UA Builder of events payload to send in the final datalayer object
 * @param {string} eventName Event name to recognize events vs ecommerce tracks
 * @param {object} originalPayload Payload sent from the app who calls track
 * @return {object} Event object to final dataLayer
 */
export const buildEventsPayload = (eventName, originalPayload) => {
  return {
    categoryEvent: eventName,
    actionEvent: originalPayload.action,
    labelEvent: originalPayload.label,
    nonInteractiveHit: !(getInteractiveEvents().includes(eventName)),
  }
}

/**
 * GA4 Builder of events payload to send in the final datalayer object
 * @param {string} eventName Event name to recognize events vs ecommerce tracks
 * @param {object} originalPayload Payload sent from the app who calls track
 * @return {object} Event object to final dataLayer
 *
 * @example
 * object = {
 *   eventName: '',
 *   parameters: {
 *     p1: '',
 *     p2: '',
 *   }
 * }
 *
 */
export const buildEventsGA4Payload = (eventName, originalPayload) => {
  const event = {
    event: normalizedEvent(eventName),
    parameters: {},
  }

  switch (eventName) {
  /** Legacy events */
  case 'validation':
    event.parameters = {
      element: originalPayload.action ?
        originalPayload.action :
        originalPayload.parameters.element,
      message: originalPayload.label ?
        originalPayload.label :
        originalPayload.parameters.message,
    }
    break
    /** Deprecate on final GA4, use modal instead */
  case 'displayed-experiment':
  case 'displayed':
    event.parameters = {
      action: originalPayload.action,
      label: originalPayload.label,
    }
    break
  case 'error':
    event.event_name = 'error_log'
    event.parameters = {
      message: originalPayload.action ?
        originalPayload.action :
        originalPayload.parameters.message,
      name: originalPayload.label ?
        originalPayload.label :
        originalPayload.parameters.errorName,
    }
    break
  case 'outbound-experiment':
  case 'outbound':
    event.parameters = {
      appeared_in: originalPayload.action ?
        originalPayload.action :
        originalPayload.parameters.appearedIn,
      redirect_to: originalPayload.label ?
        originalPayload.label :
        originalPayload.parameters.redirectTo,
    }
    break
  case 'interactive-experiment':
  case 'interactive':
    event.parameters = {
      action: originalPayload.action ?
        originalPayload.action :
        originalPayload.parameters.action,
      label: originalPayload.label ?
        originalPayload.label :
        originalPayload.parameters.label,
    }
    break
  case 'search':
    event.parameters = {
      category: originalPayload.action ?
        originalPayload.action :
        originalPayload.parameters.category,
      search_term: originalPayload.label ?
        originalPayload.label :
        originalPayload.parameters.search_term,
    }
    break
    /** Legacy events */
    /** GA4 events */
  case 'searchResults':
    event.parameters = {
      status: originalPayload.status,
      results_type: originalPayload.resultsType,
      total_results: originalPayload.totalResults,
      ...(originalPayload.orderBy ? {
        order_by: originalPayload.order_by,
      } : {}),
      ...(originalPayload.firstDeparture ? {
        first_departure: originalPayload.firstDeparture,
      } : {}),
      ...(originalPayload.lastDeparture ? {
        last_departure: originalPayload.lastDeparture,
      } : {}),
    }
    break
  case 'filterResults':
    event.parameters = {
      criteria: originalPayload.criteria,
      value: originalPayload.value,
    }
    break
  case 'orderResults':
    event.parameters = {
      criteria: originalPayload.criteria,
    }
    break
  case 'errorLog':
  case 'responseLog':
    event.parameters = {
      name: originalPayload.name,
      message: originalPayload.message,
    }
    break
  case 'validationLog':
    event.parameters = {
      field: originalPayload.field,
      message: originalPayload.message,
    }
    break
  case 'funnelStep':
  case 'checkoutStep':
    event.parameters = {
      step: originalPayload.step,
      details: originalPayload.details,
    }
    break
  case 'repricing':
    event.parameters = {
      type: originalPayload.type,
    }
    break
  case 'shortcut':
  case 'menu':
    event.parameters = {
      category: originalPayload.category,
      element: originalPayload.element,
    }
    break
  case 'modal':
    event.parameters = {
      action: originalPayload.action,
      name: originalPayload.name,
    }
    break
  case 'select':
  case 'display':
  case 'card':
  case 'show':
  case 'tab':
  case 'whatsApp':
    event.parameters = {
      action: originalPayload.action,
      label: originalPayload.label,
    }
    break
  case 'orderData':
    event.parameters = {
      ...(originalPayload.orderContext ? {
        orderContext: originalPayload.orderContext,
      } : {}),
      ...(originalPayload.itinerary ? {
        itinerary: originalPayload.itinerary,
      } : {}),
    }
    break
  default:
      // No event parameters required
  }
  /** GA4 events */
  return event
}
