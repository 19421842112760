/**
 * Core Analytics constants. These are exposed to be used by apps.
 * @typedef {Object} constants
 * @property {VARIABLES} VARIABLES - Variables available to get their data
 */

/**
 * Variables available to get their data
 * @typedef {Array} VARIABLES
 */
export const VARIABLES = [
  /*
   * Vendor variables
   */

  /**
   * `gaVariable` - To get the Google Analytics (GA) variable and their data.
   */
  'gaVariable',
]

export const isValidVariable = (variableName) => {
  return VARIABLES.includes(variableName)
}
