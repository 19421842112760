import {string, mixed, number, boolean} from 'yup'
import {messages} from '../../utils/messages'
import {
  IATA_CODE, DATE, DATE_TIME, TRAVEL_TYPES, CABINS, CURRENCY_CODES,
  FLIGHT_PATH_REGEX, SEGMENT_FLIGH_NUMBER_REGEX, SEGMENT_OPERATOR_REGEX,
  BUSINESS_UNITS, LIMIT_PASSENGERS, PAYMENT_METHODS_REGEX, ISO_31661_ALPHA2,
  ANTICIPATION_PURCHASE_DAYS, MAX_SCORE, PASSENGER_TYPE, UUID_REGEX,
  PRODUCT_CATEGORY_LEVEL_ONE, MILES_MONEY,
} from '../../business/keys'


export default {
  previousAction: string().min(3, messages.MIN_LEN),
  message: string().min(5, messages.MIN_LEN),
  errorName: string().min(3, messages.MIN_LEN),
  responseName: string().min(3, messages.MIN_LEN),
  transactionName: string().min(4, messages.MIN_LEN),
  originCountry: mixed().oneOf(ISO_31661_ALPHA2,
    'originCountry key has not valid ISO 3166-1 Alpha2 value.',
  ),
  destinationCountry: mixed().oneOf(ISO_31661_ALPHA2,
    'destinationCountry key has not valid ISO 3166-1 Alpha2 value.',
  ),
  originAirport: string().matches(IATA_CODE,
    'originAirport key has not valid IATA Code value.',
  ),
  destinationAirport: string().matches(IATA_CODE,
    'destinationAirport key has not valid IATA Code value.',
  ),
  originCity: string().matches(IATA_CODE,
    'originCity key has not valid IATA Code value.',
  ),
  destinationCity: string().matches(IATA_CODE,
    'destinationCity key has not valid IATA Code value.',
  ),
  departureDate: string().matches(DATE,
    'departureDate key has not valid Date value.',
  ),
  departureDateTime: string().matches(DATE_TIME,
    'departureDateTime key has not valid Date Time value.',
  ),
  arrivalDate: string().matches(DATE,
    'arrivalDate key has not valid Date value.',
  ),
  arrivalDateTime: string().matches(DATE_TIME,
    'arrivalDateTime key has not valid Date Time value.',
  ),
  travelType: mixed().oneOf(TRAVEL_TYPES,
    'travelType key is not a valid value.',
  ),
  cabinType: mixed().oneOf(CABINS,
    'cabinType key is not a valid value.',
  ),
  currency: mixed().oneOf(CURRENCY_CODES,
    'currency key has not valid ISO-4217 value',
  ),
  flightPath: string().matches(FLIGHT_PATH_REGEX,
    'flightPath key is not a valid value.',
  ),
  segmentFlightNumber: string().matches(SEGMENT_FLIGH_NUMBER_REGEX,
    'segmentFlightNumber key is not a valid value.',
  ),
  segmentOperator: string().matches(SEGMENT_OPERATOR_REGEX,
    'segmentOperator key is not a valid value.',
  ),
  anticipationOfPurchase: number().max(
    ANTICIPATION_PURCHASE_DAYS, messages.MAX_NUM,
  ),
  businessUnit: mixed().oneOf(BUSINESS_UNITS,
    'businessUnit key is not a valid value.',
  ),
  adults: number().max(LIMIT_PASSENGERS.ADULTS, messages.MAX_NUM),
  children: number().max(LIMIT_PASSENGERS.CHILDREN, messages.MAX_NUM),
  infants: number().max(LIMIT_PASSENGERS.INFANTS, messages.MAX_NUM),
  paymentMethod: string().matches(PAYMENT_METHODS_REGEX,
    'paymentMethod key is not a valid value.',
  ),
  order: string().ensure(),
  funnelDetails: string().ensure(),
  orderStatus: string().ensure(),
  usingMiles: boolean(),
  miles: number().min(0, messages.MIN_NUM),
  installments: number().integer(messages.NO_INT),
  checkoutAmount: number().min(0, messages.MIN_NUM),
  transactionAmount: number().min(0, messages.MIN_NUM),
  transactionCreditAmount: number().min(0, messages.MIN_NUM),
  transactionMilesAmount: number().min(0, messages.MIN_NUM),
  transactionWalletAmount: number().min(0, messages.MIN_NUM),
  promotionProduct: mixed().oneOf(PRODUCT_CATEGORY_LEVEL_ONE,
    'promotionProduct key is not a valid value.',
  ),
  promotionPrice: number().min(0, messages.MIN_NUM),
  promotionName: string().ensure(),
  promotionTargeting: string().ensure(),
  promotionDiscount: number().min(0, messages.MIN_NUM),
  promotionDepartureDate: string().matches(DATE,
    'promotiondepartureDate key has not valid Date value.',
  ),
  promotionReturnDate: string().matches(DATE,
    'promotionReturnDate key has not valid Date value.',
  ),
  promotionCabinType: mixed().oneOf(CABINS,
    'promotionCabinType key is not a valid value.',
  ),
  flightLayoverDuration: number().min(0, messages.MIN_NUM),
  flightDuration: number().positive(messages.NO_INT),
  upsellAmount: number().min(0, messages.MIN_NUM),
  upgradeAmount: number().min(0, messages.MIN_NUM),
  bookingPrice: number().min(0, messages.MIN_NUM),
  bookingPeriod: number().integer(messages.NO_INT),
  stars: number().max(MAX_SCORE, messages.MAX_NUM),
  cancellationPeriod: number().max(
    ANTICIPATION_PURCHASE_DAYS, messages.MAX_NUM,
  ),
  bookingId: string().min(3, messages.MIN_LEN),
  amenities: string().min(3, messages.MIN_LEN),
  segments: number().min(1, messages.MIN_NUM),
  passengerType: mixed().oneOf(PASSENGER_TYPE,
    'passengerType key is not a valid value.',
  ),
  appTrackId: string().matches(UUID_REGEX,
    'appTrackId key is not a valid uuid value.',
  ),
  appSessionId: string().min(3, messages.MIN_LEN),
  xpSessionId: string().min(3, messages.MIN_LEN),
  refundAmount: number().min(0, messages.MIN_NUM),
  resultsOrderBy: string().ensure(),
  orderDetails: string().ensure(),
  milesMoneyCombination: string().matches(MILES_MONEY,
    'Invalid miles money combination format.',
  ),
}
