import {analytics} from './analytics'
import {getUserData, buildCustomer} from './utils/getUserData'
import {getPageData} from './modules/page'

export default analytics

/*
 * analytics.init exported for standalone browser build
 * CDN build exposes global _analytics variable
 *
 * Initialize instance with _analytics.init() or _analytics['default']()
 */
export {analytics as init}

/*
 * analytics.Analytics exported for node usage
 *
 * Initialize instance with _analytics.init() or _analytics['default']()
 */
export {analytics as Analytics}

/*
 * getUserData exported for npm usage
 * Enables a method to get user data and return as custom dimensions
 */
export {getUserData}

/*
 * buildCustomer exported for npm usage
 * Enables a method to get user data and return as enclosed custom dimensions
 */
export {buildCustomer}

/*
 * getPageData exported for npm usage
 * Enables a method to get page data
 */
export {getPageData}
