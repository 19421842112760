import {
  buildEcommerceCustoms, buildGA4EcommerceCustoms,
} from './customDimensionsBuilder'
import {buildCategories, buildGA4Categories} from './categoriesBuilder'
import {productTypeListBuilder} from './productTypeListBuilder'

/**
 * UA Builder of product objects for Google Analytics Ecommerce
 * @param {object} originalPayload Payload sent from the app who calls track
 * @return {object}
 */
export const buildProducts = (originalPayload) => {
  return buildEcommerceCustoms(buildCategories(originalPayload), 'product')
}

/**
 * UA Builder of promotion objects for Google Analytics Ecommerce
 * @param {object} originalPayload Payload sent from the app who calls track
 * @return {object}
 */
export const buildPromotions = (originalPayload) => {
  return buildEcommerceCustoms(originalPayload, 'promotion')
}

/**
 * GA4 Builder of product objects for Google Analytics Ecommerce
 * @param {object} originalPayload Payload sent from the app who calls track
 * @return {object}
 */
export const buildGA4Products = (originalPayload) => {
  return buildGA4EcommerceCustoms(buildGA4Categories(originalPayload), 'product')
}

/**
 * GA4 Builder of promotion objects for Google Analytics Ecommerce
 * @param {object} originalPayload Payload sent from the app who calls track
 * @return {object}
 */
export const buildGA4Promotions = (originalPayload) => {
  return buildGA4EcommerceCustoms(originalPayload, 'promotion')
}

/**
 * Builder of product type list for Google Analytics Ecommerce
 * @param {object} products Products payload sent from the app who calls track
 * @return {object}
 */
export const buildProductTypeList = (products) => {
  return productTypeListBuilder(products)
}
