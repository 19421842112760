/* global VERSION */

// Business and validation schemas
import {
  validate, schemas,
} from '../utils/validations'

import {inferChannel} from './page'

// Utilities
import timeStamp from '../utils/timestamp'
import existsGTM from '../utils/existsGTM'
import reportError from '../utils/reportError'
import withCallbacks from '../utils/withCallbacks'
import {resetDataLayer} from '../utils/dataLayer'

/**
 * Pagename builder
 * @param {string} channel - The channel page value
 * @param {string} family - The Family page value
 * @param {string} subFamily - The SubFamily page value
 * @param {string} action - The Action page value
 * @param {string} pageName - The name of the page
 * @return {string} Pagename
 */
function buildPagename(channel, family, subFamily, action, pageName) {
  return `${channel}|${family}|${subFamily}|${action}|${pageName}`
}

/**
 * Builder of context payload
 * @param {string} data Data sent from the app who calls context event
 * @return {object}
 */
const buildContextPayload = (data) => {
  data.channel = inferChannel()
  return {
    screen: {
      path: data.path,
      app: data.app,
      customer_journey: data.customerJourney,
      language: data.language,
      country: data.country,
      original_country: data.originalCountry,
      title: data.title,
      ...(data.isPageview ? {
        is_pageview: data.isPageview,
      } : {}),
      ...(data.trackID ? {
        track_id: data.trackID,
      } : {}),
      page_name: buildPagename(
        data.channel,
        data.family,
        data.subFamily,
        data.action,
        data.pageName,
      ),
      ...(data.source ? {
        source: data.source,
      } : {}),
      ...(data.referrer ? {
        referrer: data.referrer,
      } : {}),
    },
    ...(data.customer ? {
      customer: {
        ...(data.customer.userID ? {
          user_id: data.customer.userID,
        } : {}),
        ...(data.customer.customerScope ? {
          customer_scope: data.customer.customerScope,
        } : {}),
        ...(data.customer.customerProfile ? {
          customer_profile: data.customer.customerProfile,
        } : {}),
        ...(data.customer.customerFFPCategory ? {
          customer_ffp_category: data.customer.customerFFPCategory,
        } : {}),
        ...(data.customer.customerGender ? {
          customer_gender: data.customer.customerGender,
        } : {}),
        ...(data.customer.customerActiveUser ? {
          customer_active_user: data.customer.customerActiveUser,
        } : {}),
        ...(data.customer.impersonatedUserID ? {
          impersonated_user_id: data.customer.impersonatedUserID,
        } : {}),
        ...(data.customer.impersonatedCustomerScope ? {
          impersonated_customer_scope: data.customer.impersonatedCustomerScope,
        } : {}),
      },
    } : {}),
    ...(data.context ? {
      context: {
        ...(data.context.originCountry ? {
          origin_country: data.context.originCountry,
        } : {}),
        ...(data.context.originAirport ? {
          origin_airport: data.context.originAirport,
        } : {}),
        ...(data.context.originCity ? {
          origin_city: data.context.originCity,
        } : {}),
        ...(data.context.destinationCountry ? {
          destination_country: data.context.destinationCountry,
        } : {}),
        ...(data.context.destinationAirport ? {
          destination_airport: data.context.destinationAirport,
        } : {}),
        ...(data.context.destinationCity ? {
          destination_city: data.context.destinationCity,
        } : {}),
        ...(data.context.departureDate ? {
          departure_date: data.context.departureDate,
        } : {}),
        ...(data.context.arrivalDate ? {
          arrival_date: data.context.arrivalDate,
        } : {}),
        ...(data.context.travelType ? {
          travel_type: data.context.travelType,
        } : {}),
        ...(data.context.businessUnit ? {
          business_unit: data.context.businessUnit,
        } : {}),
        ...(data.context.cabinType ? {
          cabin_type: data.context.cabinType,
        } : {}),
        ...(data.context.adults !== undefined ? {
          adults: data.context.adults,
        } : {}),
        ...(data.context.children !== undefined ? {
          children: data.context.children,
        } : {}),
        ...(data.context.infants !== undefined ? {
          infants: data.context.infants,
        } : {}),
        ...(data.context.transactionName ? {
          transaction_name: data.context.transactionName,
        } : {}),
        ...(data.context.originalCountry ? {
          original_country: data.context.originalCountry,
        } : {}),
        ...(data.context.order ? {
          order: data.context.order,
        } : {}),
        ...(data.context.subflow ? {
          subflow: data.context.subflow,
        } : {}),
      },
    } : {}),
    ...(data.extras ? {
      extras: data.extras,
    } : {}),
  }
}

/**
 * Validates context data and inject into datalayer method
 * @typedef {Function} context
 * @param  {Object} [data] - context data overrides.
 * @param  {Function} [dataLayer] - context tracking datalayer method
 * @param  {Function} [callback] - Callback to fire after context call completes
 * @return {Void}
 * @api private
 */
const context = (data, dataLayer, callback) => {
  return validate(schemas.context, data).then(() => {
    const eventName = 'dataInit'
    const payload = buildContextPayload(data)

    // Reset GTM context for GA4 to prevent persistance across contexts on SPA
    resetDataLayer('context')

    dataLayer({
      event: eventName,
      ...payload,
      libVersion: VERSION, // VERSION will be injected by webpack globally.
      timestamp: timeStamp(),
      ...withCallbacks(callback),
    })

    existsGTM(callback)
  }).catch((error) => reportError(error))
}

export default context
