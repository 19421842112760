/**
  * Get a cookie from browser by name
  * @typedef {Function} getCookie
  * @param {string} name - Name of the cookie
  * @return {string}
  */
export default function getCookieValue(name) {
  if (typeof document === 'undefined') {
    return false
  }

  const value = document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop() || ''
  return value === 'true' ? true : false
}
