/**
 * @name getUserData
 * @param {object} user - The user object from latamxp apps
 * @param {boolean} impersonated - Dealing with an impersonated user
 * @return {object}
 */
export function getUserData(user, impersonated = false) {
  if (user && user.userId) {
    if (impersonated) {
      return {
        impersonatedUserID: user.userId,
        impersonatedCustomerScope: Array.isArray(user.roles) ? user.roles[0] : 'no-role',
      }
    }
    return {
      userID: user.userId,
      customerScope: Array.isArray(user.roles) ? user.roles[0] : 'no-role',
      ...(user.profileType ? {customerProfile: user.profileType} : {}),
      ...(user.ffpCategory ? {customerFFPCategory: user.ffpCategory} : {}),
      ...(user.active ? {customerActiveUser: user.active} : {}),
    }
  }
  return {}
}

/**
 * @name buildCustomer
 * @param {object} user - The user object from latamxp apps
 * @param {boolean} impersonated - Dealing with an impersonated user
 * @return {object}
 */
export function buildCustomer(user, impersonated = false) {
  return {
    customer: getUserData(user, impersonated),
  }
}
