import {string, object, array} from 'yup'
import {messages} from '../../../../utils/messages'
import {ProductShape, currencyCode} from './product'
import {IMPRESSION_LISTS_REGEX} from '../../../../business/keys'

// Export schema
export const productImpression = object().shape({
  currencyCode,
  impressions: array().of(object().shape({
    ...ProductShape,
    list: string().matches(IMPRESSION_LISTS_REGEX,
      'list key is not a valid value for product impression.',
    ),
  }).noUnknown(messages.NO_UNKNOWN)).required(
    'Product impression requires an array of products.',
  ),
}).noUnknown(messages.NO_UNKNOWN)
