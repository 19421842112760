/*
* Core Analytics Events
*/

const ECOMMERCE_EVENTS = [
  /*
   * Enhanced Ecommerce
   */

  /**
   * `productImpression` - To track the display of a product to customers
   * no matters where.
   */
  'productImpression',

  /**
  * `productClick` - To track when customers clicks on a product item.
  */
  'productClick',

  /**
  * `productDetail` - To track when customers open or see the details
  * of a product.
  */
  'productDetail',

  /**
  * `productAddToCart` - To track when customers add products to shopping cart.
  */
  'productAddToCart',

  /**
  * `productRemoveFromCart` - To track when customers remove productos from
  * shopping cart.
  */
  'productRemoveFromCart',

  /**
  * `promotionImpression` - To track when we display promotions to customers.
  */
  'promotionImpression',

  /**
  * `promotionClick` - To track when we customers clicks our promotions.
  */
  'promotionClick',

  /**
  * `productCheckout` - To track when customers are checkout/pay our products.
  */
  'productCheckout',

  /**
  * `productPurchase` - To track when customers finaly purchase our products.
  */
  'productPurchase',

  /**
  * `productRefund` - To track when customers refund their products.
  */
  'productRefund',

  /**
  * `productAddPaymentInfo` - To track when customers add payment mwthod.
  */
  'productAddPaymentInfo',

  /**
  * `productBeginCheckout` - To track when customers begin checkout process.
  */
  'productBeginCheckout',
]

const NO_DL_EVENTS = [
  /*
  * No datalayer events
  */

  /**
   * `saveAction` - Save previous action to storage to be sent in the next page
   */
  'saveAction',
  /**
   * `timerStart` - Register an timer start to measure some defined metric
   */
  'timerStart',
]

const TIMER_EVENTS = [
  /*
  * Timer events
  */

  /**
   * `timerEnd` - Register a timer end to measure some defined metric
   */
  'timerEnd',
]

const BASIC_EVENTS = [
  /*
  * Basic events
  */

  /**
   * `interactive` - Registry customer interactions.
   */
  'interactive',
  /**
    * `displayed` - Register when application display a UI component
    */
  'displayed',
  /**
    * `outbound` - Register when customer interact with a component who will
    * sent it out of the page.
    */
  'outbound',
  /**
    * `error` - Register application errors displayed to customers
    */
  'error',
  /**
    * `validation` - Register validation messages displayed to customers
    */
  'validation',
  /**
    * `search` - Register searches made by customers
    */
  'search',
]

const GA4_EVENTS = [
  /*
  * GA4 events
    event documentation:
    docs.google.com/spreadsheets/d/15GqNc-W9HnUbmRdlv3SeBYJiK67XfoiKR9JRfDLChIM
  */
  'errorLog',
  'filterResults',
  'orderResults',
  'searchResults',
  'errorLog',
  'responseLog',
  'validationLog',
  'passengerSelection',
  'checkoutStep',
  'seatSelection',
  'ancillaryMarket',
  'funnelStep',
  'repricing',
  'changeProduct',
  'modal',
  'tab',
  'whatsApp',
  'show',
  'menu',
  'card',
  'shortcut',
]

const EXPERIMENT_EVENTS = [
  /*
  * Events allowed during experimentation
  */

  /**
   * `interactive` - Registry customer interactions.
   */
  'interactive-experiment',
  /**
    * `displayed` - Register when application display a UI component
    */
  'displayed-experiment',
  /**
    * `outbound` - Register when customer interact with a component who will
    * sent it out of the page.
    */
  'outbound-experiment',
]

const NON_ANALYTICS_EVENTS = [
  /*
  * Events that require tracking for other tools, NOT FOR ANALYTICS
  */

  /**
  * `orderData` - Register the data associated to an order.
  */
  'orderData',
]

const EVENTS = [
  /**
   * Basic Events
   */
  ...BASIC_EVENTS,
  /**
   * GA4 Events
   */
  ...GA4_EVENTS,
  /**
   * Experiment Events
   */
  ...EXPERIMENT_EVENTS,
  /**
   * Timer Events
   */
  ...TIMER_EVENTS,
  /**
   * Ecommerce Events
   */
  ...ECOMMERCE_EVENTS,
  /**
   * Non datalayer events to track (like actions in storage / global object)
   */
  ...NO_DL_EVENTS,
  /**
   * Non Analytics Events
   */
  ...NON_ANALYTICS_EVENTS,
]

export default EVENTS

/* Utils methods to work with events */
export const getInteractiveEvents = () => {
  return ['interactive', 'displayed', 'outbound', 'search']
}

export const needsToBeNormalized = (eventName) => {
  return ECOMMERCE_EVENTS.slice(1).includes(eventName)
}

export const isEcommerceEvent = (eventName) => {
  return ECOMMERCE_EVENTS.includes(eventName)
}

export const isTimerEvent = (eventName) => {
  return TIMER_EVENTS.includes(eventName)
}

export const isDataLayerEvent = (eventName) => {
  return EVENTS.filter((el) => {
    return !NO_DL_EVENTS.includes(el)
  }).includes(eventName)
}

export const isValidEvent = (eventName) => {
  return EVENTS.includes(eventName)
}
