// Events definitions to be tracked
import {
  isValidEvent, isDataLayerEvent,
  isEcommerceEvent, isTimerEvent,
} from '../events'

import {GA_VERSION} from '../business/keys'

// Utilities
import warn from '../utils/warn'
import timeStamp from '../utils/timestamp'
import existsGTM from '../utils/existsGTM'
import withCallbacks from '../utils/withCallbacks'
import globalContext from '../utils/global'
import isDefaultVersion from '../utils/isDefaultVersion'
import {resetDataLayer} from '../utils/dataLayer'
import {
  validate, schemas,
} from '../utils/validations'

// Error reporting for failed validations
import reportError from '../utils/reportError'

// Builders
import {
  buildEventsPayload,
  buildEventsGA4Payload,
} from '../modules/builders/events/payloadBuilder'

import {
  buildEcommercePayload,
  buildGA4EcommercePayload,
} from '../modules/builders/ecommerce/payloadBuilder'

import {
  buildTimerPayload,
  buildGA4TimerPayload,
} from '../modules/builders/timers/payloadBuilder'

// Actions
import saveAction from './actions/saveAction'
import saveTimerStart from './actions/saveTimerStart'

/**
 * Builder of track payload
 * @param {string} eventName Event name to recognize events vs ecommerce tracks
 * @param {object} originalPayload Payload sent from the app who calls track
 * @param {string} gaversion GA version to build payload
 * @return {object}
 */
const buildTrackPayload = (eventName, originalPayload, gaversion) => {
  const payloadCopy = {...originalPayload}
  if (isEcommerceEvent(eventName)) {
    return isDefaultVersion(gaversion) ?
      buildEcommercePayload(eventName, payloadCopy) :
      buildGA4EcommercePayload(eventName, payloadCopy)
  }

  if (isTimerEvent(eventName)) {
    return isDefaultVersion(gaversion) ?
      buildTimerPayload(payloadCopy) :
      buildGA4TimerPayload(payloadCopy)
  }

  return isDefaultVersion(gaversion) ?
    buildEventsPayload(eventName, payloadCopy) :
    buildEventsGA4Payload(eventName, payloadCopy)
}

/**
 * Builder of track schema.
 * @param {string} eventName Event name to recognize events vs ecommerce tracks
 * @return {object}
 */
const buildTrackSchema = (eventName) => {
  if (isEcommerceEvent(eventName)) {
    return schemas.ecommerce[eventName]
  }

  return schemas.events[eventName]
}

/**
 * Validates track events data and inject into datalayer method
 * @typedef {Function} Track
 * @param  {string} [eventName] - Event name to track
 * @param  {EventData} [payload] - Event data overrides.
 * @param  {Function} [dataLayer] - Event tracking datalayer method
 * @param  {Function} [callback] - Callback to fire after track call completes
 * @param  {boolean} [exclusiveGA4] - To fire only for GA4
 * @return {Void}
 * @api private
 */
const track = (eventName, payload, dataLayer, callback, exclusiveGA4) => {
  if (!isValidEvent(eventName)) {
    return warn('You are trying to track an uncategorized event.')
  }

  if (isDataLayerEvent(eventName)) {
    const trackSchema = buildTrackSchema(eventName)

    return validate(trackSchema, payload).then(() => {
      if (globalContext.gaVersion == null && !exclusiveGA4) {
        // eslint-disable-next-line max-len
        sendDataLayerByVersion(eventName, payload, dataLayer, null, GA_VERSION.UA)
        // eslint-disable-next-line max-len
        sendDataLayerByVersion(eventName, payload, dataLayer, callback, GA_VERSION.GA4)
      } else if (exclusiveGA4) {
        // eslint-disable-next-line max-len
        sendDataLayerByVersion(eventName, payload, dataLayer, callback, GA_VERSION.GA4)
      } else {
        // eslint-disable-next-line max-len
        sendDataLayerByVersion(eventName, payload, dataLayer, callback, globalContext.gaVersion)
      }

      return existsGTM(callback)
    }).catch((error) => reportError(error))
  }

  // If not are datalayer events, we are trying to track actions (default)
  const actions = {
    'saveAction': (payload, callback) => saveAction(payload, callback),
    'timerStart': (payload, callback) => saveTimerStart(payload, callback),
  }

  if (!actions[eventName]) {
    return warn('You are trying to track an action not available to track')
  }

  return actions[eventName](payload, callback)
}

/**
 * Build for the given GA version a payload and then send it to GA
 * @typedef {Function} sendDataLayerByVersion
 * @param  {string} [eventName] - Event name to track
 * @param  {EventData} [payload] - Event data overrides.
 * @param  {Function} [dataLayer] - Event tracking datalayer method
 * @param  {Function} [callback] - Callback to fire after track call completes
 * @param  {string} [gaversion] - Google Analytics version to build playload
 * @api private
 */
export function sendDataLayerByVersion(eventName, payload, dataLayer, callback, gaversion) { // eslint-disable-line max-len
  const {app, customerJourney} = globalContext.baseConfig
  const trackPayload = buildTrackPayload(eventName, payload, gaversion)

  // Reset ecommerce GTM object if ecommerce event
  if (isEcommerceEvent(eventName)) {
    resetDataLayer('ecommerce')
  }

  dataLayer({
    app,
    customerJourney,
    ...trackPayload,
    libVersion: VERSION, // VERSION will be injected by webpack globally.
    timestamp: timeStamp(),
    gaVersion: gaversion,
    event: eventName,
    ...withCallbacks(callback),
  })
}

export default track
