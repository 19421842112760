import {
  isFunction,
} from 'analytics-utils'

/**
 * Return the callback function from arguments received
 * @return {Function}
 */
export default function getCallbackFromArgs() {
  // eslint-disable-next-line prefer-rest-params
  return Array.prototype.slice.call(arguments).reduce((acc, arg) => {
    if (acc) return acc
    if (isFunction(arg)) {
      return arg
    }
    return acc
  }, false)
}
