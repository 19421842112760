import {GA_VERSION} from '../business/keys'

/**
  * Return whether the version being used is the default
  * @typedef {Function} isVersionDefault
  * @param  {string} [gaversion] - Google Analytics version
  * @return {Boolean}
  */
export default function isVersionDefault(gaversion) {
  return gaversion == null || gaversion == GA_VERSION.UA
}
