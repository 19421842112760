import {object, array} from 'yup'
import {messages} from '../../../../utils/messages'
import {ProductShape, currencyCode} from './product'

// Export schema
export const productDefault = object().shape({
  currencyCode,
  products: array().of(
    object().shape(ProductShape).noUnknown(messages.NO_UNKNOWN),
  ).required(
    'Product event requires an array of products.',
  ),
}).noUnknown(messages.NO_UNKNOWN)
