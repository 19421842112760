import {string, mixed, object} from 'yup'
import {messages} from '../../../utils/messages'
import {
  LANGUAGE_REGEX, POS_REGEX, JOURNEYS, UUID_REGEX, SESSION_CONTROL,
  FAMILY, SUBFAMILY, PAGE_ACTION, PAGENAME_REGEX, CHANNELS,
  CUSTOMER_SCOPE, CUSTOMER_FFP_CATEGORIES, PROFILE_TYPE,
} from '../../../business/keys'
import customVariablesShape from '../custom'

export default {
  path: string().required(
    'The path key is required.',
  ),
  app: string().required(
    'The app key is required.',
  ),
  channel: mixed().oneOf([CHANNELS.WEB, CHANNELS.MOBILE, CHANNELS.APP],
    'The channel key is not valid.',
  ),
  customerJourney: mixed().required().oneOf([JOURNEYS.O2C, JOURNEYS.D2D],
    'The customerJourney key is not valid.',
  ),
  language: string().required().matches(LANGUAGE_REGEX,
    'The language key is invalid.',
  ),
  country: string().required().matches(POS_REGEX,
    'The country key is invalid.',
  ),
  originalCountry: string().required().matches(POS_REGEX,
    'The originalCountry key is invalid.',
  ),
  title: string().required(
    'The title key is required.',
  ),
  family: mixed().required().oneOf(FAMILY,
    'The family key is not valid.',
  ),
  subFamily: mixed().required().oneOf(SUBFAMILY,
    'The subfamily key is not valid.',
  ),
  action: mixed().required().oneOf(PAGE_ACTION,
    'The action key is not valid.',
  ),
  pageName: string().required().matches(PAGENAME_REGEX,
    'The pageName key is not valid.',
  ),
  userID: string().matches(UUID_REGEX,
    'The User ID is not a valid UUID identifier.',
  ),
  assistedUserID: string().matches(UUID_REGEX,
    'The Assisted User ID is not a valid UUID identifier.',
  ),
  assistedCustomerScope: mixed().oneOf(CUSTOMER_SCOPE,
    'The Assisted Customer Scope is not valid.',
  ),
  customerScope: mixed().oneOf(CUSTOMER_SCOPE,
    'The Customer Scope is not valid.',
  ),
  customerFFPCategory: mixed().oneOf(CUSTOMER_FFP_CATEGORIES,
    'The Customer FFP Category is not valid.',
  ),
  customerProfile: mixed().oneOf(PROFILE_TYPE,
    'The Customer Profile is not valid.',
  ),
  sessionControl: mixed().oneOf(SESSION_CONTROL,
    'sessionControl key is not a valid value.',
  ),
  extras: object().noUnknown(messages.NO_UNKNOWN),
  custom: object().shape(customVariablesShape).noUnknown(messages.NO_UNKNOWN),
}
