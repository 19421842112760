// Utilities
import {isFunction, storage} from 'analytics-utils'
import warn from '../../utils/warn'

// Business keys
import {TIMERS} from '../././../business/keys'

/**
 * Save actions in the storage (locastorage by default)
 * @param {object} payload - Payload object with key and label
 * @param {Function} callback - Callback to be executed after save the action
 * @return {Function|void}
 * @api private
 */
const saveTimerStart = (payload, callback) => {
  if (TIMERS.indexOf(payload?.timerName) === -1) {
    return warn('The name used is not a valid timer to measure.')
  }

  storage.setItem(payload.timerName, +new Date())

  return isFunction(callback) ? callback() : null
}

export default saveTimerStart
