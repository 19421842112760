import {string, object, mixed, number} from 'yup'
import {messages} from '../../../../utils/messages'
import {
  PRODUCT_ID_NAME_REGEX, PRODUCT_BRAND, PRODUCT_CATEGORY_LEVEL_ONE,
  PRODUCT_CATEGORY_LEVEL_TWO, PRODUCT_CATEGORY_LEVEL_THREE_REGEX,
  PRODUCT_CATEGORY_LEVEL_FOUR_REGEX, PRODUCT_CATEGORY_LEVEL_FOUR_DEFAULT,
  PRODUCT_CATEGORY_LEVEL_FIVE_REGEX, PRODUCT_VARIANT_REGEX, PRODUCT_MIN_NUMBER,
  CURRENCY_CODES,
} from '../../../../business/keys'
import customVariablesShape from '../../custom'

// Define the category shape for products
const categoriesShape = {
  levelOne: mixed().required().oneOf(PRODUCT_CATEGORY_LEVEL_ONE,
    'Category levelOne key has not a valid value.',
  ),
  levelTwo: mixed().when('levelOne', {
    is: (levelOne) => /^(Hotels|Cars)/.test(levelOne),
    then: string(),
    otherwise: mixed().oneOf(PRODUCT_CATEGORY_LEVEL_TWO,
      'Category levelTwo key has not a valid value.'),
  },
  ),
  levelThree: mixed().when('levelOne', {
    is: (levelOne) => /^(Cars)/.test(levelOne),
    then: string(),
    otherwise: string().matches(PRODUCT_CATEGORY_LEVEL_THREE_REGEX,
      'Category levelThree key has not a valid value.'),
  },
  ),
  levelFour: mixed().when('levelOne', {
    is: (levelOne) => /^(Flights|Cars)/.test(levelOne),
    then: string().matches(PRODUCT_CATEGORY_LEVEL_FOUR_REGEX,
      'Category levelFour key has not a valid value.'),
    otherwise: mixed().oneOf(PRODUCT_CATEGORY_LEVEL_FOUR_DEFAULT,
      'Category levelFour key has not a valid value.'),
  },
  ),
  levelFive: string().matches(PRODUCT_CATEGORY_LEVEL_FIVE_REGEX,
    'Category levelFive key has not a valid value.',
  ),
}

// Define the basic product shape
const ProductShape = {
  id: string().required().matches(PRODUCT_ID_NAME_REGEX,
    'The ID is not a valid ID for this product object.',
  ),
  name: string().required().matches(PRODUCT_ID_NAME_REGEX,
    'The name is not valid for this product object.',
  ),
  brand: mixed().when('category', {
    // eslint-disable-next-line max-len
    is: (category) => /^(Hotels|Trips|Tours|Activities|Transfers|Insurance|Cars|Cruises)/.test(category.levelOne),
    then: string(),
    otherwise: mixed().oneOf(PRODUCT_BRAND,
      'The brand is not valid for this product object.'),
  },
  ),
  category: object().required().shape(categoriesShape).noUnknown(
    'The key in the category object for this product is not valid.',
  ),
  variant: mixed().when('category', {
    is: (category) => /^(Hotels|Cars|Insurance)/.test(category.levelOne),
    then: string(),
    otherwise: string().matches(PRODUCT_VARIANT_REGEX,
      'The variant is not valid for this product object.'),
  },
  ),
  quantity: number().min(PRODUCT_MIN_NUMBER, messages.MIN_NUM),
  price: number().min(PRODUCT_MIN_NUMBER, messages.MIN_NUM),
  position: number().min(PRODUCT_MIN_NUMBER, messages.MIN_NUM),
  custom: object().shape(customVariablesShape).noUnknown(messages.NO_UNKNOWN),
}

export {ProductShape}

// Define the basic product shape for refund
const ProductRefundShape = {
  id: string().required().matches(PRODUCT_ID_NAME_REGEX,
    'The ID is not a valid ID for this product object.',
  ),
  quantity: number().min(PRODUCT_MIN_NUMBER, messages.MIN_NUM),
}

export {ProductRefundShape}

// Define the reusable currencyCode validadtion
const currencyCode = mixed().oneOf(CURRENCY_CODES,
  'currency key has not valid ISO-4217 value',
)

export {currencyCode}
