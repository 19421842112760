/**
 * Builder product type list from product objects
 * @param {object} products List of products sent from the app who calls track
 * @return {string}
 */
export const productTypeListBuilder = (products) => {
  return [...new Set(products.map(extractProductType))].toString()
}

/**
 * Extract product type from category
 * @param {object} product Payload sent from the app who calls track
 * @return {string}
 */
const extractProductType = (product) => {
  // category.levelOne is required, no validation needed
  return product.category.levelOne
}
