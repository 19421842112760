import {object} from 'yup'
import {messages} from './messages'
import pageShape from '../modules/schemas/track/page'
import contextShape from '../modules/schemas/track/context'
import {
  legacy, filterResults, orderResults, errorLog, responseLog, modal, orderData,
  validationLog, passengerSelection, funnelStep, repricing, genericCategory,
  seatSelection, ancillaryMarket, searchResults, changeProduct, genericEvent,
} from '../modules/schemas/track/events'
import timerEventShape from '../modules/schemas/track/timers'

import {promotionShape} from '../modules/schemas/track/ecommerce/promotion'
import {productCheckout} from '../modules/schemas/track/ecommerce/checkout'
import {productPurchase} from '../modules/schemas/track/ecommerce/purchase'
import {productRefund} from '../modules/schemas/track/ecommerce/refund'
import {productImpression} from '../modules/schemas/track/ecommerce/impression'
import {productAddToCart} from '../modules/schemas/track/ecommerce/cart'
import {productDefault} from '../modules/schemas/track/ecommerce/default'
import {productAddPaymentInfo} from '../modules/schemas/track/ecommerce/addPaymentInfo.ga4'
import {productBeginCheckout} from '../modules/schemas/track/ecommerce/beginCheckout.ga4'

export const schemas = {
  page: object().shape(pageShape).noUnknown(messages.NO_UNKNOWN),
  context: object().shape(contextShape).noUnknown(messages.NO_UNKNOWN),
  events: {
    /** Legacy events */
    'interactive': legacy,
    'displayed': legacy,
    'outbound': legacy,
    'validation': legacy,
    'error': legacy,
    'search': legacy,
    'interactive-experiment': legacy,
    'displayed-experiment': legacy,
    'outbound-experiment': legacy,
    /** Legacy events */
    /** GA4 events */
    filterResults,
    orderResults,
    searchResults,
    errorLog,
    responseLog,
    validationLog,
    passengerSelection,
    repricing,
    seatSelection,
    ancillaryMarket,
    changeProduct,
    modal,
    orderData,
    'menu': genericCategory,
    'shortcut': genericCategory,
    'checkoutStep': funnelStep,
    'funnelStep': funnelStep,
    'wahtsApp': genericEvent,
    'select': genericEvent,
    'card': genericEvent,
    'display': genericEvent,
    'show': genericEvent,
    'tab': genericEvent,
    /** GA4 events */
    'timerEnd': object().shape(timerEventShape).noUnknown(messages.NO_UNKNOWN),
  },
  ecommerce: {
    productImpression,
    productAddToCart,
    productCheckout,
    productPurchase,
    productRefund,
    productClick: productDefault,
    productDetail: productDefault,
    productRemoveFromCart: productDefault,
    /** GA4 events */
    productAddPaymentInfo,
    productBeginCheckout,
    /** GA4 events */
    promotionImpression: promotionShape,
    promotionClick: promotionShape,
  },
}

export const validate = (schema, data) => schema.validate(data)
