/**
  * Call push method of dataLayer to send data
  * @typedef {Function} dataLayer
  * @param {Object} data - Object with data to push
  * @param {string} dataLayerName - Name of the dataLayer to push
  * @default "dataLayer"
  * @return {void}
  */
export const dataLayer = (data, dataLayerName = 'dataLayer') => {
  if (window[dataLayerName]) return window[dataLayerName].push(data)
}

/**
  * Reset dataLayer variable
  * @typedef {Function} resetDataLayer
  * @param {string} variableName - Name of the variable to reset
  * @param {string} dataLayerName - Name of the dataLayer to push
  * @default "dataLayer"
  * @return {void}
  */
export const resetDataLayer = (variableName = 'custom', dataLayerName = 'dataLayer') => {
  const resetObject = {
    parameters: undefined,
    extras: undefined,
  }

  switch (variableName) {
  case 'context':
    resetObject['customer'] = undefined
    resetObject['context'] = undefined
    resetObject['screen'] = undefined
    break
  case 'custom':
    resetObject['custom'] = undefined
    break
  case 'ecommerce':
    resetObject['ecommerce'] = undefined
    break
  }

  if (window[dataLayerName]) {
    return window[dataLayerName].push(resetObject)
  }
}
