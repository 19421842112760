import {isObject} from 'analytics-utils'

import {getPageData} from '../modules/page'
import context from '../modules/context'
import {CHANNELS} from '../business/keys'
import {dataLayer} from '../utils/dataLayer'
import getCallback from '../utils/getCallback'

export const createContextHandler = (baseConfig) => {
  /**
   * Initialize the datalayer with context data.
   * @typedef {Function} get
   * @param  {Object}   [data]  - Context data
   * @param  {Function} [callback] - Callback to fire after get completes
   * @return {Promise}
   * @api public
   *
   * @example
   *
   * // Get a gaVariable with a key called linkerParam
   * analytics.context({
   *     transactionName: 'FlightPurchase',
   * }, (value) => {
   *   console.log(value);
   * })
   */
  return (data, callback) => {
    const {path, title, referrer, internalSource} = getPageData()

    // Validate the data object
    const validatedData = isObject(data) ? data : {}

    // Set the default values when not received
    if (!validatedData.path) validatedData.path = path
    if (!validatedData.title) validatedData.title = title
    if (!validatedData.referrer) validatedData.referrer = referrer
    if (!validatedData.source) validatedData.source = internalSource
    if (!validatedData.channel) validatedData.channel = CHANNELS.WEB

    return context(
      {
        ...validatedData,
        ...baseConfig,
      },
      dataLayer,
      getCallback(validatedData, callback),
    )
  }
}
