// Event names for GA4 are converted in GTM
const ecommerceEvents = {
  'productClick': 'click',
  'productDetail': 'detail',
  'productAddToCart': 'add',
  'productRemoveFromCart': 'remove',
  'promotionImpression': 'promoView',
  'promotionClick': 'promoClick',
  'productCheckout': 'checkout',
  'productPurchase': 'purchase',
  'productRefund': 'refund',
}

export const normalizedEcommerceEvent = (eventName) => {
  return ecommerceEvents[eventName]
}
