import {string, object} from 'yup'
import {messages} from '../../../../utils/messages'
import {
  PROMO_ID_NAME_REGEX, PROMO_POSITION,
} from '../../../../business/keys'
import {currencyCode} from './product'
import customVariablesShape from '../../custom'

// Export promotion schemas
export const promotionShape = object().shape({
  currencyCode,
  promotion: object().shape({
    id: string().required().matches(PROMO_ID_NAME_REGEX,
      'The ID is not a valid ID for this promotion.',
    ),
    name: string().required().matches(PROMO_ID_NAME_REGEX,
      'The name is not valid for this promotion.',
    ),
    creative: string().ensure(),
    position: string().matches(PROMO_POSITION,
      'The position is not valid for this promotion.',
    ),
    custom: object().shape(customVariablesShape).noUnknown(messages.NO_UNKNOWN),
  }).required(
    'A promotion object is required.',
  ),
}).noUnknown(messages.NO_UNKNOWN)
