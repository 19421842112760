/**
 * UA Builder of categories for Google Analytics Ecommerce format
 * @param {object} originalPayload Payload sent from the app who calls track
 * @return {object}
 */
export const buildCategories = (originalPayload) => {
  // object is cloned to keep the first
  const payloadCopy = {...originalPayload}

  payloadCopy.category = Object.values(payloadCopy.category).join('/')

  return payloadCopy
}

/**
 * GA4 Builder of categories for Google Analytics Ecommerce format
 * @param {object} originalPayload Payload sent from the app who calls track
 * @return {object}
 */
export const buildGA4Categories = (originalPayload) => {
  // object is cloned to keep the first and categories are added
  const payloadCopy = {
    ...originalPayload,
    ...(originalPayload.category.levelOne ? {
      item_category: originalPayload.category.levelOne,
    } : {}),
    ...(originalPayload.category.levelTwo ? {
      item_category2: originalPayload.category.levelTwo,
    } : {}),
    ...(originalPayload.category.levelThree ? {
      item_category3: originalPayload.category.levelThree,
    } : {}),
    ...(originalPayload.category.levelFour ? {
      item_category4: originalPayload.category.levelFour,
    } : {}),
    ...(originalPayload.category.levelFive ? {
      item_category5: originalPayload.category.levelFive,
    } : {}),
  }
  delete payloadCopy.category

  return payloadCopy
}
