import {object, lazy} from 'yup'
import {messages} from '../../../utils/messages'
import {
  stringEnsure, stringRequired, message, name, resultsType, country, date, pnr,
  totalResults, firstDeparture, lastDeparture, booleanValue, cabinType,
  adults, children, infants, originAirport, destinationAirport, flightBrand,
  departureDateTime, arrivalDateTime,
} from '../../../utils/validations.ga4'

// Export legacy schemas: validation,displayed,error,outbound,interactive,search
export const legacy = object().shape({
  action: stringRequired,
  label: stringRequired,
}).noUnknown(messages.NO_UNKNOWN)

// Export validation_log schemas
export const validationLog = object().shape({
  field: stringEnsure,
  message,
}).noUnknown(messages.NO_UNKNOWN)

// Export search_results schemas
export const searchResults = object().shape({
  orderBy: stringEnsure,
  status: stringEnsure,
  resultsType,
  totalResults,
  firstDeparture,
  lastDeparture,
}).noUnknown(messages.NO_UNKNOWN)

// Export filter_results schemas
export const filterResults = object().shape({
  criteria: stringEnsure,
  value: stringEnsure,
}).noUnknown(messages.NO_UNKNOWN)

// Export order_results schemas
export const orderResults = object().shape({
  criteria: stringEnsure,
}).noUnknown(messages.NO_UNKNOWN)

// Export error_log schemas
export const errorLog = object().shape({
  name,
  message,
}).noUnknown(messages.NO_UNKNOWN)

// Export response_log schemas
export const responseLog = object().shape({
  name,
  message,
}).noUnknown(messages.NO_UNKNOWN)

// Export passenger_selection schemas
export const passengerSelection = object().noUnknown(messages.NO_UNKNOWN)

// Export seat_selection schemas
export const seatSelection = object().noUnknown(messages.NO_UNKNOWN)

// Export ancillary_market schemas
export const ancillaryMarket = object().noUnknown(messages.NO_UNKNOWN)

// Export change_product schemas
export const changeProduct = object().noUnknown(messages.NO_UNKNOWN)

// Export funnel/checkout_step schemas
export const funnelStep = object().shape({
  step: stringEnsure,
  details: stringEnsure,
}).noUnknown(messages.NO_UNKNOWN)

// Export repricing schemas
export const repricing = object().shape({
  type: stringEnsure,
}).noUnknown(messages.NO_UNKNOWN)

// Export menu/shortchut schemas
export const genericCategory = object().shape({
  category: stringRequired,
  element: stringRequired,
}).noUnknown(messages.NO_UNKNOWN)

// Export modal schemas
export const modal = object().shape({
  action: stringRequired,
  name,
}).noUnknown(messages.NO_UNKNOWN)

// Export select, display, whatsApp schemas
export const genericEvent = object().shape({
  action: stringRequired,
  label: stringRequired,
}).noUnknown(messages.NO_UNKNOWN)

// Export sorderData schemas
export const orderData = object().shape({
  orderContext: object().shape({
    order: stringEnsure,
    pnr: pnr,
    issueDate: date,
    country: country,
    status: stringEnsure,
    migrated: booleanValue,
    origin: stringEnsure,
    originDetails: stringEnsure,
    adults: adults,
    children: children,
    infants: infants,
  }).noUnknown(messages.NO_UNKNOWN),
  itinerary: lazy((flights) => {
    const newEntries = Object.keys(flights).reduce(
      (accumulator, currentValue) => {
        return ({
          ...accumulator,
          [currentValue]: object({
            origin: originAirport,
            destination: destinationAirport,
            departure: departureDateTime,
            arrival: arrivalDateTime,
            brand: flightBrand,
            baggageAllowances: stringEnsure,
            cabinType: cabinType,
            equipment: stringEnsure,
          }).test(
            'key',
            'Invalid key name for itinerary object.',
            () => /^flight[0-9]{1,2}$/.test(currentValue),
          ).noUnknown(messages.NO_UNKNOWN),
        })
      },
      {},
    )
    return object().shape(newEntries)
  }),
}).noUnknown(messages.NO_UNKNOWN)
