import {isObject, isString} from 'analytics-utils'

import {dataLayer} from '../utils/dataLayer'
import track from '../modules/track'
import getCallback from '../utils/getCallback'

/**
 * Track an analytics event. This will trigger `track` calls
 * @typedef {Function} Track
 * @param  {String}   eventName - Event name
 * @param  {Object}   [payload]  - Event payload
 * @param  {Function} [callback] - Callback to fire after tracking completes
 * @param  {String} [gaVersion] - Optional, specify ga4 for exclusive events
 * @return {Promise}
 * @api public
 *
 * @example
 *
 * // Event tracking with payload
 * analytics.track('displayed', {
 *   action: 'UI element name',
 *   label: 'UI area',
 * })
 *
 * // Fire callback with 2nd or 4th argument
 * analytics.track('outbound', {
 *   action: 'Search box',
 *   label: 'LATAM Pass',
 * }, () => {
 *   console.log('do this after track')
 * })
 *
 * // Action tracking with key and label
 * analytics.track('saveAction', {
 *   key: 'previousPage',
 *   label: 'Checkout Button',
 * })
 *
 * // Action tracking with key and label, using callback
 * analytics.track('saveAction', {
 *   key: 'previousPage',
 *   label: 'Checkout Button',
 * }, () => {
 *   window.location.href = '/checkout';
 * })
 *
 * // Track an start of timer event
 * analytics.track('timerStart', {
 *   timerName: 'search-box-timing'
 * })
 *
 * // Track the stop of some timer event
 * analytics.track('timerEnd', {
 *   timerName: 'search-box-timing'
 * })
 */
export const createTrackHandler =
  () => (eventName, payload, callback, gaVersion) => {
    const name = isObject(eventName) ? eventName.event : eventName
    const exclusiveGA4 = gaVersion == 'ga4' ? true : false

    if (!name || !isString(name)) {
      return warn('Event missing')
    }
    const data = payload || {}

    return track(
      name,
      {
        ...data,
      },
      dataLayer,
      getCallback(eventName, payload, callback),
      exclusiveGA4,
    )
  }
