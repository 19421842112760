// Utilities
import {storage} from 'analytics-utils'
import secondsPassedSince from '../../../utils/secondsPassedSince'

/**
 * UA Builder of timer tracking events to send in the final datalayer object
 * @param {object} originalPayload Payload with the name of what is measured
 * @return {object} Event object to final dataLayer
 */
export const buildTimerPayload = (originalPayload) => {
  // Gets the storage value based on the timer who we are measure (payload.name)
  const storageValue = +storage.getItem(originalPayload.timerName)

  // Calculate the timer value based on the seconds passed since track start
  const timerValue = secondsPassedSince(storageValue)

  // Remove the previous saved variable to end the measurement cycle
  storage.removeItem(originalPayload.timerName)

  return {
    categoryEvent: 'timing',
    actionEvent: originalPayload.timerName,
    labelEvent: timerValue,
    nonInteractiveHit: true,
  }
}

/**
 * GA4 Builder of timer tracking events to send in the final datalayer object
 * @param {object} originalPayload Payload with the name of what is measured
 * @return {object} Event object to final dataLayer
 */
export const buildGA4TimerPayload = (originalPayload) => {
  // Gets the storage value based on the timer who we are measure (payload.name)
  const storageValue = +storage.getItem(originalPayload.timerName)

  // Calculate the timer value based on the seconds passed since track start
  const timerValue = secondsPassedSince(storageValue)

  // Remove the previous saved variable to end the measurement cycle
  storage.removeItem(originalPayload.timerName)

  return {
    event: 'timing',
    parameters: {
      name: originalPayload.timerName,
      time: timerValue,
    },
  }
}
